import i18next from 'i18next';
import Content from './base';
import { config as i18nextConfig } from '../../translations';

class Locales extends Content {
  getLocales() {
    this.response = this.request('get', 'locales');

    return this.response;
  }

  changeLanguage(locale) {
    if (locale) {
      i18next.changeLanguage(locale);
    } else {
      i18next.changeLanguage(i18nextConfig.lng);
    }
  }
}

export default Locales;
