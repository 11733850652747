import Service from '../../service';
import Account from '../../models/casino/account';

class ForgotPassword extends Service {
  constructor(params) {
    super();
    this.params = params;
    this.model = new Account();
  }

  async callProcessStep(step) {
    this.response = await this.model[step](this.params);
  }
}

export default ForgotPassword;
