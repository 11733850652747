class Cookie {
  set(name, value, expiration = 30) {
    const expDate = new Date();
    expDate.setTime(expDate.getTime() + (expiration * 60 * 1000));
    document.cookie = `${name}=${JSON.stringify(value)};expires=${expDate.toUTCString()};path=/`;
  }

  get(name) {
    return document.cookie.split('; ').reduce((returnValue, currentValue) => {
      const parts = currentValue.split('=');
      return parts[0] === name ? decodeURIComponent(parts[1]) : returnValue;
    }, '');
  }

  delete(name) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
  }
}
export default Cookie;
