/* Istanbul ignore file */
import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import PromotionSelector from './PromotionSelector';
import BonusValidator from './BonusValidator';

const removeBonusFromUrl = () => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);

  params.delete('bonus');
  const newUrl = `${window.location.pathname}${params.toString() ? `?${ params.toString()}` : ''}`;
  window.history.replaceState(null, null, newUrl);
};

export const SelectorItem = ({
  children, header, isActive, onClick,
}) => (
  <div className={`m-cashier-accordion__item${isActive ? ' is-active' : ''}`}>
    <button className="m-cashier-accordion__header is-size-5 selector-item-button" type="button" onClick={onClick}>
      {header}
    </button>
    {children}
  </div>
);

SelectorItem.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.node.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export const SelectorBody = ({ onDepositClick, onCancelClick }) => (
  <div className="m-cashier-first-step__body has-text-centered has-text-light-grey">
    <p>{i18next.t('deposit_wo_bonus')}</p>
    <button
      type="button"
      className="button is-large is-success mt-3 mb-3 js-hide-show"
      data-hide="#js-cashier-bonuses"
      data-show="#cashier-deposit-content"
      onClick={onDepositClick}
    >
      {i18next.t('deposit')}
    </button>
    <p>
      <a className="has-text-light-grey button-modal-close" href="/#" onClick={onCancelClick}>
        {i18next.t('cancel')}
      </a>
    </p>
  </div>
);

SelectorBody.propTypes = {
  onDepositClick: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
};

const PreBonusSelector = ({
  onBonusSelect,
  bonuses,
  filteredBonuses,
  children,
  onCancel,
  onDepositWoBonus,
  isLoading,
  preSelected,
}) => {
  const [state, setState] = useReducer(
    (prevState, newState) => ({
      ...prevState,
      ...newState,
    }),
    {
      activeItem: '',
      availableBonuses: [],
      preSelectedBonus: null,
    },
  );

  const onItemClick = (value) => {
    const newValue = value === state.activeItem ? '' : value;
    setState({ activeItem: newValue });
  };

  useEffect(() => {
    const filtered = [...filteredBonuses];
    let activeItem = '';
    let preSelectedBonus = false;

    if (preSelected) {
      const bonusFound = bonuses
        .find(bonus => preSelected.toString() === bonus.bonusPlanId?.toString());

      if (bonusFound) {
        filtered.push(bonusFound);
        activeItem = 'promotions';
        preSelectedBonus = preSelected;
      } else {
        activeItem = 'validator';
      }
      removeBonusFromUrl();
    }

    setState({
      // makes the items of array unique
      availableBonuses: [...new Map(filtered.map(item => [item['bonusPlanId'], item])).values()],
      activeItem,
      preSelectedBonus,
    });
  }, [filteredBonuses]);

  return (
    <div className="m-cashier-first-step">
      <div id="js-cashier-bonuses" className="m-cashier-first-step__content">
        <div id="js-cashier-accordion" className="m-cashier-accordion">
          <SelectorItem
            header={
              <>
                <b className="m-cashier-accordion__header-text is-uppercase">
                  {i18next.t('current_promotions')}
                  {state.availableBonuses?.length > 0 && (
                    <span className="tag is-danger is-rounded">{state.availableBonuses?.length}</span>
                  )}
                </b>
                {isLoading ? <span className="bulma-loader-mixin" /> : <i className="fas fa-caret-left" />}
              </>
            }
            isActive={state.activeItem === 'promotions'}
            onClick={() => onItemClick('promotions')}
          >
            <PromotionSelector
              onPromotionSelect={onBonusSelect}
              promotions={state.availableBonuses}
              isLoading={isLoading}
              preSelected={state.preSelectedBonus}
            />
          </SelectorItem>
          <SelectorItem
            header={
              <>
                <b className="m-cashier-accordion__header-text is-uppercase">{i18next.t('have_bonus_code')}</b>
                {isLoading ? <span className="bulma-loader-mixin" /> : <i className="fas fa-caret-left" />}
              </>
            }
            isActive={state.activeItem === 'validator'}
            onClick={() => onItemClick('validator')}
          >
            <BonusValidator
              onBonusSelect={onBonusSelect}
              bonuses={bonuses}
              isLoading={isLoading}
              preSelected={preSelected}
            />
          </SelectorItem>
          <SelectorBody onCancelClick={onCancel} onDepositClick={onDepositWoBonus} />
          {children}
        </div>
      </div>
    </div>
  );
};

PreBonusSelector.propTypes = {
  children: PropTypes.node,
  bonuses: PropTypes.instanceOf(Array).isRequired,
  filteredBonuses: PropTypes.instanceOf(Array),
  onBonusSelect: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDepositWoBonus: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  preSelected: PropTypes.bool,
};

PreBonusSelector.defaultProps = {
  children: null,
  isLoading: false,
  filteredBonuses: [],
  preSelected: false,
};

export default PreBonusSelector;
