import LegacyBase from './base';

class History extends LegacyBase {
  constructor() {
    super();
    this.customerslUrl = 'customers';
    this.paymentslUrl = 'payments';
    this.bonuseslUrl = 'bonuses';
    this.gameslUrl = 'game-sessions';
    this.transactionsUrl = 'transactions';
  }

  async fetchCustomers(parameters) {
    this.response = await this.request('get', `${this.customerslUrl}`, parameters);

    return this.response;
  }

  async fetchPayments(parameters) {
    this.response = await this.request('get', `${this.paymentslUrl}`, parameters);

    return this.response;
  }

  async fetchBonuses(parameters) {
    this.response = await this.request('get', `${this.bonuseslUrl}`, parameters);

    return this.response;
  }

  async fetchGameSessions(parameters) {
    this.response = await this.request('get', `${this.gameslUrl}`, parameters);

    return this.response;
  }

  async fetchTransactions(parameters) {
    this.response = await this.request('get', `${this.transactionsUrl}`, parameters);

    return this.response;
  }
}
export default History;
