import CasinoBase from './base';

class Signup extends CasinoBase {
  async preSignupCheck() {
    this.response = await this.request('post', 'preSignupCheck', {});

    return this.response;
  }

  async signup(parameters) {
    this.response = await this.request('post', 'signup', parameters);

    return this.response;
  }

  async quickSignup(parameters) {
    this.response = await this.request('post', 'quickSignup', parameters);

    return this.response;
  }

  async quickSignupCompletion(parameters) {
    this.response = this.request('post', 'quickSignupCompletion', parameters);

    return this.response;
  }
}

export default Signup;
