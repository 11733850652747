import Service from '../../service';
import Account from '../../models/casino/account';
import Bonus from '../../models/casino/bonus';
import Cookie from '../../cookie';

class ClearUserOptInBonuses extends Service {
  constructor() {
    super();
    this.accountModel = new Account();
    this.bonusModel = new Bonus();
  }

  async run() {
    const cookie = new Cookie();
    if (!cookie.get('Session')) return;

    const session = JSON.parse(cookie.get('Session'));
    const { sessionKey, partyId } = session;

    const allBonuses = await this.bonusModel.all();

    allBonuses.data.bonuses.forEach(async (bonus) => {
      if (bonus.status === 'OPTED_IN') {
        await this.accountModel.optOutBonus({
          sessionKey,
          partyId,
          bonusId: bonus.id,
        });
      }
    });
    return allBonuses;
  }
}

export default ClearUserOptInBonuses;
