import CasinoBase from './base';

class Session extends CasinoBase {
  constructor(username, password, executed = 'PLAYER') {
    super();
    this.username = username;
    this.password = password;
    this.executed = executed;
  }

  async create() {
    this.response = await this.request('post', 'login', { username: this.username, password: this.password });

    return this.response;
  }

  async destroy(cookieContent) {
    this.response = await this.request('get', 'logout', { executedBy: this.executed, sessionKey: cookieContent });

    return this.response;
  }
}
export default Session;
