import Response from '../response';

class SubPagesInfoResponse extends Response {
  found() {
    return (typeof this.data.items !== 'undefined');
  }

  items() {
    if (!this.found()) return undefined;

    return this.data.items;
  }
}

export default SubPagesInfoResponse;
