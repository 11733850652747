/* eslint-disable class-methods-use-this */
import i18next from 'i18next';

class Rest {
  async request(method, endpoint, parameters, extraHeaders = {}) {
    const params = (parameters) ? JSON.stringify(parameters) : null;
    let result;
    let headers = {};

    if (!endpoint.includes(process.env.REACT_APP_API_OMEGA_URL)
      && !endpoint.includes(process.env.REACT_APP_TRANSLATION_URL)
    ) {
      const qaHostUrl = process.env.REACT_APP_FRONTEND_URL;
      const Authorization = process.env.REACT_APP_CMS_AUTHORIZATION;
      headers = {
        'X-Forwarded-Host': qaHostUrl,
        'accept-language': '*',
        'Content-language': i18next.language,
        Authorization,
      };
    }

    headers = Object.assign(headers, extraHeaders);

    switch (method) {
      case 'get':
        result = this.get(endpoint, params, headers);
        break;
      case 'getStream':
        result = this.getStream(endpoint, params, headers);
        break;
      case 'getNotJson':
        result = this.get(endpoint, params, headers, false);
        break;
      case 'delete':
        result = this.delete(endpoint, params, headers);
        break;
      case 'patch':
        result = this.patch(endpoint, params, headers);
        break;
      case 'put':
        result = this.put(endpoint, parameters, headers);
        break;
      case 'postNotJson':
        result = this.postNotJson(endpoint, parameters, headers);
        break;
      case 'postJson':
        result = this.postJson(endpoint, params, headers);
        break;
      case 'post':
      default:
        result = this.post(endpoint, params, headers);
        break;
    }
    return result;
  }

  async get(endpoint, parameters, headers, json = true) {
    let url = endpoint;

    if (parameters) {
      const queryString = this.objToQueryString(JSON.parse(parameters));
      url = `${endpoint}?${queryString}`;
    }

    const response = await fetch(url, {
      method: 'get',
      headers,
    });
    const data = (json) ? await response.json() : await response.text();

    return data;
  }

  /* istanbul ignore next */ 
  async getStream(endpoint, parameters, headers) {
    let url = endpoint;

    if (parameters) {
      const queryString = this.objToQueryString(JSON.parse(parameters));
      url = `${endpoint}?${queryString}`;
    }

    const data = await this.fetchStream(url, headers);
    return data;
  }

  /* istanbul ignore next */ 
  async fetchStream(url, headers) {
    return fetch(url, {
      method: 'get',
      headers,
    })
      .then(async (response) => {
        const res = await response.text();
        const json = JSON.stringify(res).replace(/\]\[/g, ',');
        const stringA = JSON.parse(JSON.parse(json));

        return stringA;
      }).then(stringA => stringA)
    // Error
      .catch(err => console.error(err));
  }

  // eslint-disable-next-line class-methods-use-this
  async post(endpoint, parameters, headers) {
    const response = await fetch(endpoint, {
      method: 'post',
      body: new URLSearchParams(JSON.parse(parameters)),
      headers,
    });
    const data = await response.json();
    return data;
  }

  // eslint-disable-next-line class-methods-use-this
  async patch(endpoint, parameters, headers) {
    const patchHeaders = {
      ...headers,
      'Content-Type': 'application/json',
    };

    const response = await fetch(endpoint, {
      method: 'PATCH',
      body: parameters,
      headers: patchHeaders,
      mode: 'cors',
    });
    const data = await response.json();
    return data;
  }

  async put(endpoint, parameters, headers) {
    const response = await fetch(endpoint, {
      method: 'PUT',
      body: parameters,
      headers,
    });
    const data = await response.json();
    return data;
  }

  async postJson(endpoint, parameters, headers) {
    const postHeaders = {
      ...headers,
      'Content-Type': 'application/json',
    };

    const response = await fetch(endpoint, {
      method: 'post',
      body: parameters,
      headers: postHeaders,
      mode: 'cors',
    });
    const data = await response.json();
    return data;
  }

  async postNotJson(endpoint, parameters, header) {
    const response = await fetch(endpoint, {
      method: 'post',
      body: parameters,
      headers: header,
    });
    const data = await response.json();
    return data;
  }

  async delete(endpoint, parameters, headers) {
    const queryString = this.objToQueryString(JSON.parse(parameters));
    const response = await fetch(`${endpoint}?${queryString}`, {
      method: 'delete',
      headers,
    });
    const data = await response.json();
    return data;
  }

  objToQueryString(obj) {
    const keyValuePairs = [];
    for (const key in obj) {
      keyValuePairs.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`);
    }
    return keyValuePairs.join('&');
  }
}
export default Rest;
