import Response from '../response';

class RedirectResponse extends Response {
  found() {
    return (typeof this.data.id !== 'undefined');
  }

  type() {
    if (typeof this.data.meta.type === 'undefined') return undefined;

    return this.data.meta.type.split('.')[1];
  }
}

export default RedirectResponse;
