import ForgotPassword from './forgotPassword';

class ForgotPasswordStep1And2 extends ForgotPassword {
  async run() {
    await this.callProcessStep('forgotPasswordStep1And2');

    return this.response;
  }
}

export default ForgotPasswordStep1And2;
