import Model from '../model';

class LegacyBase extends Model {
  constructor(endpoint = process.env.REACT_APP_API_LEGACY_URL) {
    super();
    this.endpoint = endpoint;
    this.response = null;
  }

  async fetch(url) {
    this.response = await this.request('get', url);

    return this.response;
  }
}

export default LegacyBase;
