import Service from '../../service';
import Customer from '../../models/legacy/customer';
import Signin from '../signin';
import Account from '../../models/casino/account';

class ValidateLegacyPlayer extends Service {
  constructor(parameters) {
    super();
    this.model = new Customer();
    this.parameters = parameters;
  }

  async run() {
    const { username, password } = this.parameters;
    const siteId = process.env.REACT_APP_SITE_ID;

    const player = await this.model.fetch(`?username=${username}&site_id=${siteId}`);
    const oldUserId = (player.items().length > 0) ? player.items()[0].legacy_user_id : null;

    // if NOT UPDATED user found in legacy db
    if (player.items().length > 0 && !player.items()[0].password_updated) {
      const paramsForValidation = {
        ...this.parameters,
        site_id: siteId,
      };
      const validatePassword = await this.model.validatePassword(paramsForValidation);

      // if password matches with legacy password
      if (validatePassword.data) {
        const login = await new Signin(username, player.items()[0].new_password, oldUserId).perform();

        if (login.success()) {
          const { sessionKey } = login.data;
          const updatePassword = await new Account().updatePassword(sessionKey, player.items()[0].new_password, password);

          // set user as updated in our legacy db
          if (updatePassword.success()) {
            const paramsForUpdate = {
              password_updated: true,
            };

            await this.model.update(player.items()[0].id, paramsForUpdate);
          }
        }
        super.response = login;
        return;
      }
    }
    super.response = await new Signin(username, password, oldUserId).perform();
  }
}

export default ValidateLegacyPlayer;
