import Session from '../models/casino/session';
import Service from '../service';
import Cookie from '../cookie';
import Account from '../models/casino/account';

class Signin extends Service {
  constructor(username, password, oldUserId = null, userConsents = null) {
    super();
    this.session = new Session(username, password);
    this.cookie = new Cookie();
    this.username = username;
    this.oldUserId = oldUserId;
    this.userConsents = userConsents;
  }

  /*
   * Perform the heavy lifting for this service, trigger respective callbacks from frontend
   */
  async run() {
    const response = await this.session.create();
    let spinnalotQuickReg = false;

    // if spinnalot and player status is "QUICK_REG" the don't have to log in
    if (process.env.REACT_APP_FRONTEND_URL.includes('spinnalot') && response.data?.registrationStatus === 'QUICK_REG') {
      spinnalotQuickReg = true;
    }

    if (response.success() && !spinnalotQuickReg) {
      const sessionKey = response.getdata('sessionKey');
      let tac;
      let pp;
      let rec_emails = null;

      if (this.userConsents === null) {
        const userConsentsRes = await new Account().userConsents({ sessionKey });

        if (userConsentsRes.success()) {
          const { data: { userConsents } } = userConsentsRes;
          tac = this.filterConsentByKey(userConsents, 'TERMS_AND_CONDITIONS')?.consentStatus === 'CONSENTED';
          pp = this.filterConsentByKey(userConsents, 'PRIVACY_POLICY')?.consentStatus === 'CONSENTED';
          rec_emails = this.filterConsentByKey(userConsents, 'EMAIL')?.consentStatus === 'CONSENTED';
        }
      } else {
        tac = this.userConsents.tac;
        pp = this.userConsents.pp;
        rec_emails = this.userConsents.rec_emails;
      }

      response.data.termsPolicy = {
        tac,
        pp,
        rec_emails,
      };

      const cookieContent = {
        username: response.getdata('username'),
        email: response.getdata('email'),
        registrationStatus: response.getdata('registrationStatus'),
        sessionKey,
        currency: response.getdata('currency'),
        language: response.getdata('language'),
        partyId: response.getdata('partyId'),
        oldUserId: this.oldUserId,
        tac,
        pp,
        rec_emails,
      };
      this.cookie.set(this.cookieName, cookieContent, process.env.REACT_APP_TIMEOUT_MINUTES);
    }

    super.response = response;
  }

  filterConsentByKey(consents, key) {
    if (typeof consents === 'undefined' || consents === null) return null;
    return consents.find(
      consent => consent.consent.key === key,
    );
  }
}
export default Signin;
