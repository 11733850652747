import CasinoBase from './base';

class Game extends CasinoBase {
  getJackpotsByGame(parameters) {
    this.response = this.request('get', 'getJackpotsByGame', parameters);

    return this.response;
  }

  getWinnerList(parameters) {
    this.response = this.request('get', 'getWinnerList', parameters);

    return this.response;
  }

  getGameWinnerList(parameters) {
    this.response = this.request('get', 'getGameWinnerList', parameters);

    return this.response;
  }
}

export default Game;
