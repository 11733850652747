import CasinoBase from './base';
import Cookie from '../../cookie';

class Bonus extends CasinoBase {
  async all(startDate = null, endDate = null) {
    const cookie = new Cookie();
    if (!cookie.get('Session')) return;

    const session = JSON.parse(cookie.get('Session'));
    const { sessionKey } = session;

    const parameters = {};

    if (startDate) {
      parameters.startDate = startDate;
    }

    if (endDate) {
      parameters.endDate = endDate;
    }

    this.response = await this.request('get', 'getBonuses', { ...parameters, sessionKey });

    return this.response;
  }

  async getActiveBonuses() {
    const cookie = new Cookie();
    if (!cookie.get('Session')) return;

    const session = JSON.parse(cookie.get('Session'));
    const { sessionKey } = session;

    this.response = await this.request('get', 'getActiveBonuses', { sessionKey });

    return this.response;
  }
}

export default Bonus;
