import Content from './base';
import RedirectResponse from '../../rest/content/redirectResponse';
import Rest from '../../rest';

class Redirect extends Content {
  constructor() {
    super();
    this.findPath = 'redirects/find/';
    this.pages = 'redirects/';
  }

  async fetch(url) {
    this.response = await this.request('get', this.findPath, { html_path: url });

    return this.response;
  }

  async request(method, path, parameters) {
    const rest = new Rest();
    const request = await rest.request(method, this.endpointFor(path), parameters);

    return Redirect.responseFor(request);
  }

  static responseFor(response) {
    return new RedirectResponse(response);
  }
}

export default Redirect;
