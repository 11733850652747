import i18next from 'i18next';
import { config as i18nextConfig } from '../translations';
import Response from './response';

i18next.init(i18nextConfig);

class LimitsResponse extends Response {
  validationMessage() {
    return i18next.t(`setPersonalDepositLimits.${this.data.reason}`);
  }
}

export default LimitsResponse;
