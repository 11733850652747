import Model from '../model';

/**
 * Provides a base class for defining common behaviours shared across any models that interact
 * with the Casino API
 *
 * This should be kept abstract, and not be called directly.
 */
class CasinoBase extends Model {
  constructor() {
    super();
    this.endpoint = process.env.REACT_APP_API_OMEGA_URL;
    this.response = null;
  }

  static addBrandIdToParameters(parameters) {
    let params = parameters;
    if (params) {
      params.brandId = process.env.REACT_APP_BRAND_ID;
    } else {
      params = {
        brandId: process.env.REACT_APP_BRAND_ID,
      };
    }
    return params;
  }

  async request(method, path, parameters) {
    const params = CasinoBase.addBrandIdToParameters(parameters);

    return super.request(method, path, params);
  }
}
export default CasinoBase;
