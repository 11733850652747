import React from 'react';
import { useUserAction, useUserData } from '../hooks/userHooks';

const withUserHOC = Component => function WrappedComponent(props) {
  const userData = useUserData();
  const { updateUser } = useUserAction();

  return <Component {...props} updateUser={updateUser} user={userData} />;
};

export default withUserHOC;
