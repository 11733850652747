/* eslint-disable class-methods-use-this */
import Surveys from '../../models/content/surveys';
import FetchContent from './fetchContent';

class FetchSurveys extends FetchContent {
  constructor(parameters) {
    super(parameters);
    this.parameters = parameters;
  }

  getSurveyById(surveyId) {
    const surveyById = new Surveys('surveys').getSurveys(surveyId);
    return surveyById;
  }

  getSurveyResults(parameters) {
    const surveyresults = new Surveys('surveyresults').getSurveys(parameters);
    return surveyresults;
  }

  getAllSurvey() {
    const surveys = new Surveys('surveys').getSurveys();
    return surveys;
  }

  createSurvey(surveyDetails) {
    const createdSurveys = new Surveys('surveys').createSurveys(surveyDetails);
    return createdSurveys;
  }

  createSurveyResults(surveyDetails) {
    const createdSurveyresults = new Surveys('surveyresults/').createSurveys(surveyDetails);
    return createdSurveyresults;
  }

  async updteSurveyById(surveyId, data) {
    const updatedSurveyResults = await new Surveys(`surveyresults/${surveyId}`).editSurveys(data);
    return updatedSurveyResults;
  }

  async uploadDocumentById(files) {
    const updatedSurveyResults = await new Surveys(`surveyresults/${files.id}/upload-file`).uploadSurveyDocuments(files);
    return updatedSurveyResults;
  }
}

export default FetchSurveys;
