import React, { useState, useEffect } from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { getLangIso } from '../../utilities/helpers';

const BonusValidator = ({
  bonuses, onBonusSelect, isLoading, preSelected,
}) => {
  const [validatedBonus, setValidatedBonus] = useState(null);
  const [bonusCode, setBonusCode] = useState('');
  const [isError, setIsError] = useState(false);
  const langIso = getLangIso();

  const validateBonusCode = (code = null) => {
    let validSubBonus;

    const bonusToValidate = code || bonusCode;

    let validBonus = bonuses.find((bonus) => {
      if (Array.isArray(bonus)) {
        validSubBonus = bonus.find(subBonus => (
          subBonus.bonusPlanId.toString() === bonusToValidate.toString()
        ));
        return false;
      }
      return bonus.bonusPlanId.toString() === bonusToValidate.toString();
    });

    if (validSubBonus && !validBonus) validBonus = validSubBonus;

    if (validBonus) {
      setValidatedBonus(validBonus);
      setIsError(false);
    } else {
      setValidatedBonus(null);
      setIsError(true);
      setBonusCode('');
    }
  };

  const selectBonus = () => {
    onBonusSelect(validatedBonus.bonusPlanId);
  };

  const onBonusChange = (e) => {
    setBonusCode(e.target.value);
    setIsError(false);
  };

  useEffect(() => {
    if (preSelected && !isLoading) {
      validateBonusCode(preSelected);
      setBonusCode(preSelected);
    }
  }, [preSelected, isLoading]);

  return (
    <>
      <div className="m-cashier-accordion__content">
        <div id="js-cashier-bonus-input" className="field is-grouped">
          <p className="control">
            <input
              className={`input has-background-white has-text-black bonus-input${isError ? ' is-danger' : ''}`}
              type="text"
              placeholder={i18next.t('bonus_code')}
              onChange={onBonusChange}
              value={bonusCode}
            />
            {isError && (
              <span className="help is-danger">{i18next.t('invalid_bonuscode')}</span>
            )}
          </p>
          <p className="control">
            <button
              type="button"
              className="button is-white is-outlined js-hide-show"
              onClick={() => validateBonusCode()}
              disabled={bonusCode.toString().trim() === '' || isLoading}
            >
              {i18next.t('proceed')}
            </button>
          </p>
        </div>
        {(validatedBonus && !isError) && (
          <div
            id="js-cashier-bonus-success"
            className="m-cashier-accordion__success-content has-text-centered has-text-light-grey"
          >
            <h4 className="mb-2 has-text-white is-size-5">
              <b>
                {i18next.t('your_bonus_is_valid')}
              </b>
            </h4>
            {validatedBonus?.description?.[langIso]?.Description && (
              <p className="mb-3">
                {i18next.t('bonus_will_added', { description: validatedBonus?.description?.[langIso]?.Description })}
              </p>
            )}
            <button
              className="button is-large is-white is-outlined js-hide-show deposit-button"
              type="button"
              onClick={selectBonus}
            >
              {i18next.t('deposit')}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

BonusValidator.propTypes = {
  bonuses: PropTypes.instanceOf(Array),
  onBonusSelect: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  preSelected: PropTypes.bool,
};

BonusValidator.defaultProps = {
  bonuses: [],
  isLoading: false,
  preSelected: false,
};

export default BonusValidator;
