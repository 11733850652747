import Model from '../model';

class StakeLogicBase extends Model {
  constructor() {
    super();
    this.endpoint = process.env.REACT_APP_STAKELOGIC_BASE_URL;
  }

  jackpots(parameters) {
    this.response = this.request('post', 'jackpot-values/STAKELOGIC_STANDARD', parameters);

    return this.response;
  }
}
export default StakeLogicBase;
