import CasinoBase from './base';

/**
 * Example country model allowing us to fetch country data from the Casino API.
 */

class Country extends CasinoBase {
  all() {
    this.response = this.request('get', 'getCountries', '');

    return this.response;
  }

  getCountryInfo() {
    return this.request('get', 'getCountryInfo');
  }
}
export default Country;
