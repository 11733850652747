import Service from '../service';
import MauticBase from '../models/mautic/base';

class AddPlayerMautic extends Service {
  constructor(params) {
    super();
    this.params = params;
    this.mautic = new MauticBase();
    this.auth = process.env.REACT_APP_MAUTIC_AUTH;
  }

  async run() {
    const extraHeaders = {
      Authorization: `Basic ${this.auth}`,
    };
    await this.mautic.request('post', 'api/contacts/new', this.params, extraHeaders);
  }
}

export default AddPlayerMautic;
