import Model from '../model';
import Rest from '../../rest';

/**
 * Provides a base class for defining common behaviours shared across any models that interact
 * with the Casino API
 *
 * This should be kept abstract, and not be called directly.
 */
class TranslateBase extends Model {
  constructor() {
    super();
    this.endpoint = process.env.REACT_APP_TRANSLATION_URL;
    this.response = null;
  }

  async request(method, path, parameters, extraHeaders = {}) {
    const rest = new Rest();
    const request = await rest.request(method, this.endpointFor(path), parameters, extraHeaders);

    return request;
  }
}
export default TranslateBase;
