import CasinoBase from './base';

class Currency extends CasinoBase {
  static formatCurrencyData(items) {
    const currenciesList = {
      EUR: 'Euro',
      USD: 'United States Dollar',
      HUF: 'Hungarian',
      GBP: 'British Pound',
      SEK: 'Swedish krona',
      NOK: 'Norwegian krone',
      AUD: 'Australian dollar',
      CAD: 'Canadian dollar',
      NZD: 'New Zealand dollar',
      ZAR: 'South African rand',
      CHF: 'Swiss frank',
      PEN: 'Peruvian sol',
      PLN: 'Polish zloty',
      BRL: 'Brazilian Real',
      MXN: 'Mexican peso',
    };

    const formatedData = items.map((item) => {
      let currency = currenciesList[item];
      if (!currency) {
        currency = item;
      }

      return { value: item, name: currency };
    });

    return formatedData;
  }

  async all() {
    let items = [];
    this.response = await this.request('get', 'getBrandCurrencies', {});
    if (this.response.success() && this.response.data.currencies.length) {
      items = Currency.formatCurrencyData(this.response.data.currencies);
    }

    return items;
  }
}

export default Currency;
