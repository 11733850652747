import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import FetchPage from '../services/content/fetchPage';

class BlogFetch extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
    };
  }

  componentDidMount() {
    this.fetchPage();
  }

  fetchPage = async () => {
    const { blogUrl, lang } = this.props;
    const page = await new FetchPage(`/${lang}/${blogUrl}`).perform();

    if (page.found()) {
      this.setState({
        data: page.data,
      });
    }
  };

  render() {
    const { state, props } = this;

    return props.children({
      data: state.data,
    });
  }
}

BlogFetch.propTypes = {
  blogUrl: PropTypes.string,
  lang: PropTypes.string,
  children: PropTypes.node.isRequired,
};

BlogFetch.defaultProps = {
  blogUrl: `/${i18next.t('slugs.blog')}`,
  lang: 'en-int',
};

export default BlogFetch;
