import Service from '../../service';
import SubPagesInfoModel from '../../models/content/subPagesInfo';

class FetchSubPagesInfo extends Service {
  constructor(pageId) {
    super();
    this.model = new SubPagesInfoModel();
    this.pageId = pageId;
  }

  async run() {
    this.response = await this.model.fetch(this.pageId);
    return this.response;
  }
}
export default FetchSubPagesInfo;
