import CasinoBase from './base';
import Rest from '../../rest';
import LimitsResponse from '../../rest/limitsResponse';

class Limits extends CasinoBase {
  getRealityCheck(parameters) {
    this.response = this.request('get', 'getRealityCheck', parameters);

    return this.response;
  }

  updateRealityCheck(playerSessionKey, interval) {
    this.response = this.request('post', 'updateRealityCheck', {
      sessionKey: playerSessionKey, interval,
    });

    return this.response;
  }

  getPersonalDepositLimits(parameters) {
    this.response = this.request('get', 'getPersonalDepositLimits', parameters);

    return this.response;
  }

  setPersonalDepositLimits(parameters) {
    this.response = this.request('post', 'setPersonalDepositLimits', parameters);

    return this.response;
  }

  getLimits(parameters) {
    this.response = this.request('get', 'getLimits', parameters);

    return this.response;
  }

  addPeriodLimit(parameters) {
    this.response = this.request('get', 'addResponsibleGamingLimit', parameters);

    return this.response;
  }

  updatePeriodLimit(parameters) {
    this.response = this.request('get', 'updateResponsibleGamingLimit', parameters);

    return this.response;
  }

  getPeriodLimit(parameters) {
    this.response = this.request('get', 'getResponsibleGamingLimit', parameters);

    return this.response;
  }

  getSessionLimit(parameters) {
    this.response = this.request('get', 'getSessionLimit', parameters);

    return this.response;
  }

  setWagerLimit(parameters) {
    this.response = this.request('post', 'updateWagerLimit', parameters);

    return this.response;
  }

  setLossLimit(parameters) {
    this.response = this.request('post', 'updateLossLimit', parameters);

    return this.response;
  }

  setSessionLimit(parameters) {
    this.response = this.request('post', 'updateSessionLimit', parameters);

    return this.response;
  }

  lockPlayer(playerSessionKey, isPermanent, lockType, lockPeriodUnit, lockPeriod) {
    this.response = this.request('post', 'lockPlayer', {
      sessionKey: playerSessionKey, isPermanent, lockType, lockPeriodUnit, lockPeriod,
    });

    return this.response;
  }

  lockPlayerUntilDate(playerSessionKey, lockUntil) {
    this.response = this.request('post', 'lockPlayer', {
      sessionKey: playerSessionKey, lockUntil,
    });

    return this.response;
  }

  static responseFor(response) {
    return new LimitsResponse(response);
  }

  async request(method, path, parameters) {
    const rest = new Rest();
    const request = await rest.request(method, this.endpointFor(path), parameters);

    return Limits.responseFor(request);
  }
}

export default Limits;
