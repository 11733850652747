import TranslateBase from './base';

class Translate extends TranslateBase {
  async translateText(parameters) {
    this.response = await this.request('post', '', parameters);

    return this.response;
  }
}
export default Translate;
