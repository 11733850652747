import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Accordion = ({ data }) => {
  const [isClosed, setIsClose] = useState(true);

  const onClickHandler = () => {
    setIsClose(!isClosed);
  };

  return (
    <>
      {data.seo?.site
        && (
          <div className={`m-seo-accordion container is-fluid ${isClosed ? 'is-closed' : ''}`}>
            <div className="columns is-centered">
              <div className="column is-9 content has-text-centered">
                <h2 className="m-seo-accordion-title is-size-4">
                  <button type="button" className="m-seo-accordion-button" onClick={onClickHandler}>
                    {data.seo.title || ''}
                    <i className="icon-arrow-down" />
                  </button>
                </h2>
                {/* eslint-disable-next-line react/no-danger */}
                <div dangerouslySetInnerHTML={{ __html: data.seo.body || '' }} />
              </div>
            </div>
          </div>
        )}
    </>
  );
};

Accordion.propTypes = {
  data: PropTypes.shape({
    seo: PropTypes.shape({
      title: PropTypes.string,
      body: PropTypes.string,
      site: PropTypes.number.isRequired,
    }),
  }),
};

Accordion.defaultProps = {
  data: {
    seo: {
      title: '',
      body: '',
    },
  },
};

export default Accordion;
