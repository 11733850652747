import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Cookie from '../cookie';
import Account from '../models/casino/account';

class LanguageSelector extends PureComponent {
  constructor() {
    super();
    this.state = {
      isActive: false,
      error: null,
      isLoaded: false,
      openUpwards: false,
      googleLanguage: 'en-int',
    };
  }

  componentDidMount() {
    this.setInitialLanguage();
  }

  componentDidUpdate(prevProps) {
    const { userLoggedIn } = this.props;
    if (prevProps.userLoggedIn !== userLoggedIn) {
      this.setInitialLanguage();

      if (userLoggedIn) {
        setTimeout(() => window.location.reload(), 200);
      }
    }
  }

  setInitialLanguage = async () => {
    const { languageList } = this.props;
    const { state } = this;

    try {
      const sessionCookie = new Cookie().get('Session');
      const googleLanguage = localStorage.getItem('googleLanguage');
      const objectVal = languageList;

      if (sessionCookie) {
        const { sessionKey } = JSON.parse(sessionCookie);

        const { data: { language } } = await new Account().fetchPlayerInfo(sessionKey);

        localStorage.setItem('googleLanguage', language);

        this.setState({
          ...state,
          isLoaded: objectVal.length > 0,
          googleLanguage: language,
        });
      } else {
        this.setState({
          ...state,
          isLoaded: objectVal.length > 0,
          googleLanguage,
        });
      }
    } catch (error) {
      throw new Error(error.message);
    }
  }

  updateLanguageInOmega = async (languageCode) => {
    const sessionCookie = new Cookie().get('Session');

    if (sessionCookie) {
      const { sessionKey } = JSON.parse(sessionCookie);

      if (sessionKey) {
        const parameters = {
          sessionKey,
          languageCode,
        };

        try {
          const result = await new Account().updatePlayerInfo(parameters);
          // eslint-disable-next-line no-restricted-globals
          if (result.status) return location.reload();
        } catch (error) {
          throw Error(error);
        }
      }
    }

    // eslint-disable-next-line no-restricted-globals
    return location.reload();
  }

  toggleMenu = () => {
    const { isActive } = this.state;
    this.setState({ isActive: !isActive });
  };

  // this is to display 'en-au' as 'AU' in the language selector
  mapISO = iso => (iso === 'en-au' ? 'au' : iso);

  changeLocale = (event, locale) => {
    event.preventDefault();

    localStorage.setItem('googleLanguage', locale);

    this.setState({
      googleLanguage: locale,
    });
    this.toggleMenu();
    this.updateLanguageInOmega(locale);
    // location.reload();
  };

  getCurrentLocaleInfo = () => {
    const { languageList } = this.props;
    const objectVal = languageList;

    // Get index of locale info to use in the locales array
    const index = objectVal.findIndex(p => p.locale === this.state.googleLanguage);
    return objectVal[index] ? objectVal[index] : { language: '', iso: '', locale: '' };
  };

  setMyInputRef = (element) => {
    this.inputRef = element;
  };

  render() {
    const { error, isLoaded } = this.state;
    const { languageList } = this.props;
    const objectVal = languageList;

    if (error) {
      return (
        <div className="has-text-white error-message">
          Error:
          {error.message}
        </div>
      );
    } if (!isLoaded) {
      return <p><i className="fa fa-spinner has-text-white" /></p>;
    }

    if (objectVal.length === 1) return null;

    const { isActive } = this.state;
    const { openUpwards } = this.props;

    // adds a css class to locale item if item is the current selected locale
    const hideSelectedLocaleItemWithCSS = (locale) => {
      const hiddenClass = '';
      // if (locale === i18n.language) {
      // hiddenClass = 'is-hidden';
      // }

      return hiddenClass;
    };

    let counter = 0;
    const languageItems = objectVal.map((item, key) => {
      counter += 1;
      return (
        <a
          id={`locale-link-${key}`}
          key={counter}
          onClick={e => this.changeLocale(e, item.locale)}
          title={item.language}
          href="/#"
          className={`dropdown-item is-size-7 ${hideSelectedLocaleItemWithCSS(item.locale)}`}
        >
          <div className="dropdown-item-content">
            <span className="iso is-uppercase">{this.mapISO(item.iso)}</span>
            <i className={`fflag ff-md fflag-${item.iso}`} />
          </div>
        </a>
      );
    });

    const isActiveClasses = `${openUpwards ? 'is-active is-up' : 'is-active'}`;
    const extraClasses = `${isActive ? isActiveClasses : ''}`;

    return (
      <>
        <div
          ref={(node) => { this.node = node; }}
          className={`m-language-menu dropdown is-right ${extraClasses}`}
          onClick={this.toggleMenu}
          role="presentation"
        >
          <div className="dropdown-trigger">
            <button
              type="button"
              title={`${this.getCurrentLocaleInfo().language}`}
              id="locale-selector-button"
              className="m-top-nav-item is-size-7"
              aria-haspopup="true"
              aria-controls="dropdown-menu"
            >
              <span className="is-uppercase">{ this.mapISO(this.getCurrentLocaleInfo().iso) }</span>
              <i className={`current-locale flag-${this.getCurrentLocaleInfo().locale} fflag ff-md fflag-${this.getCurrentLocaleInfo().iso}`}>
                <span className="sr-only">{this.getCurrentLocaleInfo().locale}</span>
              </i>
              <span className="icon is-small">
                <i
                  className={`fas ${isActive ? 'fa-angle-up' : 'fa-angle-down'}`}
                  aria-hidden="true"
                />
              </span>
            </button>
          </div>
          <div className="dropdown-menu" id="dropdown-menu" role="menu">
            <div className="dropdown-content has-background-dark has-text-white">
              { languageItems }
            </div>
          </div>
        </div>
      </>
    );
  }
}

LanguageSelector.propTypes = {
  languageList: PropTypes.instanceOf(Object),
  openUpwards: PropTypes.instanceOf(Object),
  userLoggedIn: PropTypes.bool.isRequired,
};

export default LanguageSelector;
