import de from './de/index.json';
import en from './en/index.json';
import es from './es/index.json';
import fi from './fi/index.json';
import fr from './fr/index.json';
import hu from './hu/index.json';

export default {
  en: { common: en },
  es: { common: es },
  de: { common: de },
  fr: { common: fr },
  fi: { common: fi },
  hu: { common: hu },
};
