import Model from '../model';

class MauticBase extends Model {
  constructor() {
    super();
    this.endpoint = process.env.REACT_APP_MAUTIC_BASE_URL;
  }

  async request(method, path, params, extraHeaders = {}) {
    return super.request(method, path, params, extraHeaders);
  }
}
export default MauticBase;
