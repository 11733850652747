import Service from '../service';
import Country from '../models/casino/country';
import Locales from '../models/content/locales';
import Cookie from '../cookie';

class GeolocationCheck extends Service {
  async run() {
    const cookie = new Cookie();
    if (cookie.get('Geolocation-Redirected')) return;

    const currentCountry = await new Country().getCountryInfo();
    const availableCountries = await new Locales().getLocales();
    const code = currentCountry.data.countryInfo.iso2Code.toLowerCase();
    const countries = availableCountries.data.items;
    let target = '/en-eu';

    countries.forEach((item) => {
      if (item.locale.includes(code)) target = `/${item.locale}`;
    });

    cookie.set('Geolocation-Redirected', true);

    super.response = target;
  }
}
export default GeolocationCheck;
