import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Cookie from '../cookie';

export const SourceOfWealthBodyWrapper = ({ children }) => (
  <main className="main-content-parent main-content-parent-short">
    <div className="has-background-dark main-content-parent">
      <section className="section m-page-wrapper">
        <div className="container is-fluid">
          <div className="columns is-centered is-mobile">
            <div className="column is-12-mobile is-12-tablet is-9-widescreen m-page-width-wrapper">
              <div className="content typography">{children}</div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </main>
);

SourceOfWealthBodyWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export const SourceOfWealth = ({ questionnaireURL }) => {
  document.title = 'Source of Wealth Questionnaire';
  const partyId = useMemo(() => {
    const sessionCookie = new Cookie().get(process.env.REACT_APP_API_COOKIE_NAME);

    try {
      const sessionObject = JSON.parse(sessionCookie);
      return sessionObject.partyId;
    } catch (e) {
      return '';
    }
  }, [process.env.REACT_APP_API_COOKIE_NAME]);

  return (
    <SourceOfWealthBodyWrapper>
      <div>
        <div className="box source-of-wealth-box has-background-black">
          <div className="content typography has-link-underline fixed-scrollable has-background-mid-grey source-of-wealth-container">
            <iframe
              id="source-of-wealth-form"
              title="Source of Wealth"
              src={`${questionnaireURL}?partyid=${partyId}`}
            />
          </div>
        </div>
      </div>
    </SourceOfWealthBodyWrapper>
  );
};

SourceOfWealth.propTypes = {
  questionnaireURL: PropTypes.string.isRequired,
};

export default SourceOfWealth;
