/**
 * Common interface class for all Models regardless of their respective APIs.
 *
 * This should be kept abstract, and not be called directly.
 */
import Rest from '../rest';
import Response from '../rest/response';

class Model {
  constructor() {
    this.endpoint = null;
  }

  endpointFor(path) {
    return `${this.endpoint}${path}`;
  }

  responseFor(response, path) {
    this.response = new Response(response, path);
    if (path !== 'logout') {
      if (this.response.sessionMissingError()) window.location.href = '/logout';
      if (this.response.sessionIsRestricted()) window.location.href = '/restricted-withdraw/?restricted=true';
    }

    return this.response;
  }

  async request(method, path, parameters, extraHeaders = {}) {
    const rest = new Rest();
    const request = await rest.request(method, this.endpointFor(path), parameters, extraHeaders);

    return this.responseFor(request, path);
  }
}
export default Model;
