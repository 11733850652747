import React, { useState } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import PromotionWelcombo, { PromotionWelcomboContent } from './PromotionWelcombo';
import { getLangIso } from '../../utilities/helpers';

export const PromotionPlaceholder = ({ content }) => (
  <div className="m-cashier-promotions promotion-placeholder">
    <h3 className="is-size-5 has-text-grey is-uppercase">
      <i>
        {content.bonusPlanName}
      </i>
    </h3>
    <h2 className="has-text-black m-cashier-promotion__title">
      {content.description?.[getLangIso()]?.subTitle}
    </h2>
    <p className="has-text-black has-text-weight-bold">
      {content.description?.[getLangIso()]?.Description}
    </p>
    <button
      className="button"
      id="claim-button"
    >
      {i18next.t('claim_offer')}
    </button>
  </div>
);

PromotionPlaceholder.propTypes = {
  content: PropTypes.instanceOf(Object).isRequired,
};

export const PromotionContent = ({
  content,
  selectedOption,
  onCancel,
  onDepositClick,
}) => (
  <div
    id="js-promotions-content"
    className={`m-cashier-promotions${content.bonusPlanId !== selectedOption ? ' is-hidden' : ''}`}
  >
    <div className="has-text-centered m-cashier-promotion-detail m-chasier-selected-bonus-title">
      <button
        id="delete"
        type="button"
        className="delete js-hide-show promotion-cancel"
        aria-label="close"
        onClick={onCancel}
      />
      <h3 className="is-size-5 has-text-white is-uppercase"><b>{content.bonusPlanName}</b></h3>
      <h2 className="is-size-2 has-text-grey m-cashier-promotion__title">{content.description?.[getLangIso()]?.subTitle}</h2>
      <p className="has-text-grey has-text-weight-bold">{content.description?.[getLangIso()]?.Description}</p>
    </div>
    <div className="has-text-centered">
      <button
        type="button"
        className="button promotion-deposit is-large is-success mt-3 mb-3 js-hide-show"
        onClick={onDepositClick}
      >
        {i18next.t('deposit')}
      </button>
      <p onClick={onCancel} className="has-text-underline is-clickable">{i18next.t('cancel')}</p>
    </div>
  </div>
);

PromotionContent.propTypes = {
  selectedOption: PropTypes.number.isRequired,
  content: PropTypes.instanceOf(Object).isRequired,
  onCancel: PropTypes.func.isRequired,
  onDepositClick: PropTypes.func.isRequired,
};

const PromotionSelector = ({
  promotions, onPromotionSelect, isLoading, preSelected,
}) => {
  const [selectedOption, setSelectedOption] = useState(0);
  const langIso = getLangIso();

  const onOptionClick = (option) => {
    setSelectedOption(option);
  };

  const onDepositClick = (option) => {
    onPromotionSelect(option);
  };

  React.useEffect(() => {
    if (preSelected) {
      setSelectedOption(preSelected);
    } else {
      setSelectedOption(0);
    }
  }, [preSelected]);

  return (
    <div className="m-cashier-accordion__content py-0 px-0">
      <div
        id="js-promotion-options"
        className={`m-cashier-promotions m-cashier-accordion__content${!!selectedOption ? ' is-hidden' : ''}`}
      >
        <div className="columns">
          {isLoading && <span className="bonus-loader">{`${i18next.t('loading')}...`}</span>}
          {(!isLoading && promotions?.length === 0) && (
            <span>
              {i18next.t('no_bonus_found')}
            </span>
          )}
          {promotions?.map((promotion) => {
            if (Array.isArray(promotion)) {
              return (
                <PromotionWelcombo
                  onOptionClick={onOptionClick}
                />
              );
            }

            return (
              <div
                className="column is-6 has-text-centered"
                key={promotion.bonusPlanId}
              >
                <button
                  type="button"
                  className={`m-cashier-promotion-item js-hide-show ${!promotion.description?.[langIso]?.thumbnail ? 'placeholder-container' : ''}`}
                  onClick={() => onOptionClick(promotion.bonusPlanId)}
                >
                  {promotion.description?.[langIso]?.thumbnail ? (
                    <img
                      src={promotion.description?.[langIso]?.thumbnail}
                      alt={promotion.bonusPlanId}
                    />
                  ) : (
                    <PromotionPlaceholder
                      content={promotion}
                    />
                  )}
                </button>
              </div>
            );
          })}
        </div>
      </div>
      {promotions?.map((promotion) => {
        if (Array.isArray(promotion)) {
          return (
            <PromotionWelcomboContent
              key="welcombo_content"
              selectedOption={selectedOption}
              content={promotion}
              onCancel={() => onOptionClick('')}
              onDepositClick={onDepositClick}
            />
          );
        }

        return (
          <PromotionContent
            key={promotion.bonusPlanId}
            selectedOption={selectedOption}
            content={promotion}
            onCancel={() => onOptionClick('')}
            onDepositClick={() => onDepositClick(promotion.bonusPlanId)}
          />
        );
      })}
    </div>
  );
};

PromotionSelector.propTypes = {
  promotions: PropTypes.arrayOf(PropTypes.shape({
    bonusPlanId: PropTypes.number.isRequired,
  })).isRequired,
  onPromotionSelect: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  preSelected: PropTypes.bool,
};

PromotionSelector.defaultProps = {
  isLoading: false,
  preSelected: null,
};

export default PromotionSelector;
