import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import FetchContent from '../services/content/fetchContent';

export const PageContext = React.createContext();

export class PageContextProvider extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pageType: null,
      prevPageType: null,
      layout: {
        topMenu: true,
        footer: true,
        header: true,
      },
    };
  }

  componentDidMount() {
    this.fetchSettings();
  }

  fetchSettings = async () => {
    const { state } = this;
    const result = await new FetchContent('site-settings/').perform();

    if (result.data.items.length > 0) {
      this.setState({
        ...state,
        merchantId: result.data.items[0].merchant_id,
        maintenanceMode: result.data.items[0].maintenance_mode,
        maintenanceTime: result.data.items[0].maintenance_time,
      });
    }
  };

  setPageType = (pageType) => {
    const { props, state } = this;

    this.setState(prevState => ({
      ...prevState,
      prevPageType: prevState.pageType,
      pageType,
    }));

    if (props.onPageTypeChange) props.onPageTypeChange(pageType, state.prevPageType);
  };

  setLayoutItemVisibility = (config) => {
    const { state } = this;

    const newConfig = {
      ...state.layout,
      ...config,
    };

    if (JSON.stringify(newConfig) === JSON.stringify(state.layout)) return;

    this.setState(prevState => ({
      ...prevState,
      layout: {
        ...prevState.layout,
        ...config,
      },
    }));
  };

  render() {
    const { children } = this.props;
    const { state } = this;

    return (
      <PageContext.Provider
        value={
          {
            pageType: state.pageType,
            layout: state.layout,
            prevPageType: state.prevPageType,
            merchantId: state.merchantId,
            maintenanceMode: state.maintenanceMode,
            maintenanceTime: state.maintenanceTime,
            setPageType: this.setPageType,
            setLayoutItemVisibility: this.setLayoutItemVisibility,
          }
        }
      >
        {children}
      </PageContext.Provider>
    );
  }
}

PageContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  onPageTypeChange: PropTypes.func,
};

PageContextProvider.defaultProps = {
  onPageTypeChange: null,
};
