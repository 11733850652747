import LegacyBase from '../../models/legacy/base';
import Service from '../../service';

class Legacy extends Service {
  constructor(parameters) {
    super();
    this.model = new LegacyBase();
    this.parameters = parameters;
  }

  async getLegacyProviders() {
    const providers = await this.model.fetch('providers');
    return providers;
  }

  async getLegacyProviderById(providerId) {
    const providers = await this.model.fetch(`providers/?id=${providerId}`);
    return providers;
  }

  async getLegacyGames() {
    const providers = await this.model.fetch('games');
    return providers;
  }

  async getLegacyGameById(gameId) {
    const providers = await this.model.fetch(`games/?gam_game_id=${gameId}`);
    return providers;
  }

  async getLegacyTransactions() {
    const providers = await this.model.fetch('transactions');
    return providers;
  }

  async getLegacyTransactionById(sessionId) {
    const providers = await this.model.fetch(`transactions/?session_id=${sessionId}`);
    return providers;
  }
}

export default Legacy;
