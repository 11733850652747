import Service from '../../service';
import PageModel from '../../models/content/page';
import Cookie from '../../cookie';

class SendMail extends Service {
  constructor(method, url, firstName = '', lastName = '', body, email, subject) {
    super();
    this.model = new PageModel();
    this.url = url;
    this.parameters = {
      first_name: firstName, last_name: lastName, body, email, subject,
    };
  }

  async run() {
    if (this.parameters.body === 'VIP membership request') {
      const cookie = new Cookie();
      this.response = { success: true, data: 'Vip apply is already requested!' };
      if (cookie.get('vip-apply')) return this.response;
      cookie.set('vip-apply', true, 1440);
    }
    this.response = await this.model.request(this.method, this.url, this.parameters);
    return this.response;
  }
}
export default SendMail;
