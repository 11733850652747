import i18next from 'i18next';
import momentTz from 'moment-timezone';
import { config as i18nextConfig } from '../translations';

i18next.init(i18nextConfig);

class Response {
  constructor(response, path = '') {
    this.data = response;
    this.path = path;
  }

  success() {
    return (this.data.status === 'SUCCESS');
  }

  getStatus() {
    return this.data.status || 'STATUS_NOT_SET';
  }

  errors() {
    return this.data.errors;
  }

  items() {
    return this.data.items;
  }

  sessionMissingError() {
    return this.data.status === 'NOT_LOGGED_IN_ERROR';
  }

  sessionIsRestricted() {
    return this.data.status === 'RESTRICTED_ACTION';
  }

  setMessage(message) {
    if (message !== undefined) {
      this.data.message = message;
    }
    return this.data.message;
  }

  message() {
    if (this.path === 'uploadMultiUserDocument' && this.data.message.includes('File post limit exceeded')) {
      return i18next.t('post_image_size_exceed');
    }

    if (this.getStatus() === 'GENERIC_ERROR') return i18next.t('sorry_error_occurred');

    if (this.path === 'resendVerificationCode' && this.getStatus() === 'STATUS_NOT_SET') {
      return i18next.t('resendVerificationCode.NOT_FOUND');
    }

    if (
      process.env.REACT_APP_FRONTEND_URL.includes('spinnalot')
      && this.path === 'login'
      && ['VALIDATION_FAIL', 'FAIL_UN_PW'].includes(this.getStatus())
    ) {
      return i18next.t('login.spinnalot.FAIL_UN_PW');
    }

    this.formatDates();

    return (i18next.exists(`${this.path}.${this.getStatus()}`) ? i18next.t(`${this.path}.${this.getStatus()}`, this.data) : this.data.message);
  }

  getdata(field) {
    return eval(`this.data.${field}`);
  }

  formatDates() {
    const datesToFormat = ['lockUntilDateFormatted'];

    Object.keys(this.data).map((key) => {
      if (datesToFormat.includes(key)) {
        const localTz = momentTz.tz.guess();
        const currentLocal = i18next.language.split('-')[0];

        this.data[key] = momentTz.tz(this.data[key], localTz).locale(currentLocal).format('YYYY-MM-DD HH:mm:ss');
      }
      return true;
    });
  }
}
export default Response;
