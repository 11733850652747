import Service from '../service';
import SignupConfirmation from '../models/casino/signupConfirmation';

class SignupConfirmationCode extends Service {
  constructor(parameters) {
    super();
    this.parameters = parameters;
    this.signupConfirmation = new SignupConfirmation(this.parameters);
  }

  async run() {
    const response = await this.signupConfirmation.create();

    super.response = response;
  }
}
export default SignupConfirmationCode;
