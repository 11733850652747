/* Istanbul ignore file */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import momentTz from 'moment-timezone';
import CryptoService from '../services/payment/cryptoService';
import Account from '../models/casino/account';

export const CryptoContext = React.createContext();

export const useCryptoContext = () => React.useContext(CryptoContext);

export class CryptoContextProvider extends PureComponent {
  constructor(props) {
    super(props);
    this.service = new CryptoService(props.sessionKey);
    this.menthodName = 'CP_COINSPAID';
  }

  getCryptoAddresses = async () => {
    const result = await this.service.getCryptoAddresses();
    if (!result.success()) return [];

    return result.data.list;
  };

  getCryptoCurrencies = async () => {
    const result = await this.service.getCryptoCurrencies();
    if (!result.success()) return [];

    return result.data.currencies;
  };

  createCryptoAddress = async (currency) => {
    const result = await this.service.createCryptoAddress(currency);
    if (!result.success()) return [];

    return result.data.list;
  };

  getWithdrawalMethods = async () => {
    const result = await this.service.getWithdrawalMethods();

    if (!result.success()) return [];

    return result.data.withdrawalMethods;
  };

  getPlayerBonuses = async () => {
    const result = await this.service.getPlayerBonuses();

    if (!result.success()) return [];

    return result.data.optInBonusPlans;
  };

  cryptoWithdrawal = async (currency, amount, address) => {
    const result = await this.service.cryptoWithdrawal(currency, amount, address);

    return result.success();
  };

  submitBonus = async (optInId) => {
    const result = await this.service.submitBonus(optInId);

    return result.success();
  };

  cancelBonus = async (bonusId) => {
    const result = await this.service.cancelBonus(bonusId);

    return result.success();
  };

  checkIfPaymentSuccessful = async (startDate) => {
    const { sessionKey } = this.props;
    const endDate = momentTz().format('YYYY-MM-DD');
    const localTz = momentTz.tz.guess();

    const params = {
      sessionKey,
      startDate: startDate.split(' ')[0],
      endDate,
      order: 'DESC',
    };

    const bankingHistory = await new Account().getBankingHistory(params);

    return bankingHistory.data.histories.find((history) => {
      const processDate = momentTz.tz(history.processDate, localTz);

      return history.paymentMethod === this.menthodName && processDate > momentTz(startDate);
    });
  };

  getWithdrawalLimits = async () => {
    const methods = await this.getWithdrawalMethods();
    const cryptoWithdrawal = methods.find(
      method => method.code === this.menthodName || method.paymentMethod === this.menthodName,
    );

    if (cryptoWithdrawal) {
      return {
        min: cryptoWithdrawal.minimumWithdrawal.replace(',', '').split('.')[0],
        max: cryptoWithdrawal.maximumWithdrawal.replace(',', '').split('.')[0],
      };
    }

    return {
      min: '20',
      max: '500',
    };
  };

  onSuccess = () => {
    const { props } = this;

    props.onSuccess();
  };

  render() {
    const { children, sessionKey } = this.props;

    return (
      <CryptoContext.Provider
        value={{
          getCryptoAddresses: this.getCryptoAddresses,
          getCryptoCurrencies: this.getCryptoCurrencies,
          createCryptoAddress: this.createCryptoAddress,
          checkIfPaymentSuccessful: this.checkIfPaymentSuccessful,
          getWithdrawalMethods: this.getWithdrawalMethods,
          cryptoWithdrawal: this.cryptoWithdrawal,
          onSuccess: this.onSuccess,
          getPlayerBonuses: this.getPlayerBonuses,
          submitBonus: this.submitBonus,
          getWithdrawalLimits: this.getWithdrawalLimits,
          sessionKey,
        }}
      >
        {children}
      </CryptoContext.Provider>
    );
  }
}

CryptoContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  onSuccess: PropTypes.func.isRequired,
  sessionKey: PropTypes.string.isRequired,
};
