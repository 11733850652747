import i18next from 'i18next';
import currencyMark from './currencies';

export const getCurrencyMark = currency => currencyMark[currency] || currency;

export const getLangIso = () => i18next.language.split('-')[0];

export const isEmptyObject = obj => (
  obj
    && Object.keys(obj).length === 0
);
