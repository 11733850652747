import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import CustomSelect from './CustomSelect';
import QrCode from './QrCode';
import { getCurrencyMark } from '../utilities/helpers';
import Skeleton from './Skeleton';
import currencyMark from '../utilities/currencies';

export const CryptoNotification = ({ type, children }) => (
  <div className={`notification ${type}`}>
    {children}
  </div>
);

CryptoNotification.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node.isRequired,
};

CryptoNotification.defaultProps = {
  type: 'is-success',
};

export const CryptoDeposit = ({
  isLoading,
  selectedAddress,
  currencies,
  onSelect,
  onCopy,
  onChange,
  onBonusSubmit,
  submittedBonus,
  bonusCode,
  selectedCurrency,
  depositResponse,
  bonusSelector,
}) => (
  <>
    <section>
      <div className="crypto-labels-wrapper">
        <div>{i18next.t('crypto.select_crypto')}</div>
        <div>{i18next.t('crypto.address')}</div>
        {(!bonusSelector || (bonusSelector && !!bonusCode)) && <div>{i18next.t('crypto.bonus_code')}</div>}
      </div>
      <div>
        <CustomSelect
          items={currencies}
          onSelect={onSelect}
        />
        {isLoading && <Loader />}
        <div className="qr-code-wrapper">
          {selectedAddress && !isLoading && (
            <span className="qr-code">
              <QrCode value={`bitcoin:${selectedAddress.address}`} />
            </span>
          )}
        </div>
        {(!bonusSelector || (bonusSelector && !!bonusCode)) && (
          <div className="bonus-code-wrapper">
            <input
              type="text"
              name="bonusCode"
              onChange={onChange}
              readOnly={submittedBonus !== null || bonusSelector}
              value={bonusCode}
            />
            <button
              onClick={onBonusSubmit}
              disabled={submittedBonus !== null || bonusCode.trim() === ''}
            >
              {i18next.t('crypto.submit')}
            </button>
          </div>
        )}
      </div>
      <div />
    </section>
    <section>
      {selectedAddress && (
        <>
          <p>
            {i18next.t('crypto.send_any_amount', { currency: selectedCurrency })}
          </p>
          <div className="qr-copy-content">
            <span>{selectedAddress.address}</span>
            <button
              onClick={() => onCopy(selectedAddress.address)}
            >
              {i18next.t('crypto.copy')}
            </button>
          </div>
          {selectedAddress.tag && (
            <div className="qr-copy-content crypto-tag">
              {i18next.t('crypto.memo_tag')}
              &nbsp;
              <span>{selectedAddress.tag}</span>
              <button
                onClick={() => onCopy(selectedAddress.tag)}
              >
                {i18next.t('crypto.copy')}
              </button>
            </div>
          )}
          <div className="payment-result">
            {!depositResponse.isPaymentSuccess
              && (
                <>
                  <Skeleton height="20" width="40" />
                  <p className="pl-3">
                    {i18next.t('crypto.payment_result_pending')}
                  </p>
                </>
              )}
            {depositResponse.isPaymentSuccess
              && 'id' in depositResponse.depositDetails
                && (
                  <p className="pl-3">
                    {currencyMark[depositResponse.depositDetails.currency]}
                    {depositResponse.depositDetails.amount}
                    &nbsp;
                    {i18next.t('crypto.payment_result_success')}
                  </p>
                )}
          </div>
        </>
      )}
      {!selectedAddress && !isLoading && (
        <p className="crypto-deposit-error">
          <i className="fas fa-exclamation-triangle" />
          {i18next.t('crypto.deposit_error')}
        </p>
      )}
    </section>
    <section>
      <p>
        {i18next.t('crypto.this_is_your_private_address')}
        &nbsp;
        <span dangerouslySetInnerHTML={{ __html: i18next.t('crypto.limits_link') }} />
        &nbsp;
        {i18next.t('crypto.be_careful', { currency: selectedCurrency })}
        &nbsp;
        {i18next.t('crypto.any_transaction_you_make')}
      </p>
    </section>
  </>
);

CryptoDeposit.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  selectedAddress: PropTypes.instanceOf(Object).isRequired,
  submittedBonus: PropTypes.instanceOf(Object),
  currencies: PropTypes.instanceOf(Array).isRequired,
  onSelect: PropTypes.func.isRequired,
  onCopy: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onBonusSubmit: PropTypes.func.isRequired,
  bonusCode: PropTypes.string.isRequired,
  selectedCurrency: PropTypes.string.isRequired,
  depositResponse: PropTypes.shape({
    isPaymentSuccess: PropTypes.bool.isRequired,
    depositDetails: PropTypes.instanceOf(Object),
  }),
  bonusSelector: PropTypes.bool,
};

CryptoDeposit.defaultProps = {
  submittedBonus: null,
  depositResponse: {
    isPaymentSuccess: false,
    depositDetails: {},
  },
  bonusSelector: false,
};

export const CryptoWithdrawal = ({
  isLoading,
  currencies,
  onSelect,
  withdrawalAddress,
  withdrawalAmount,
  onChange,
  onRequest,
  errorMessage,
  playerCurrency,
  withdrawalLimits,
  selectedCurrency,
}) => (
  <>
    <section>
      <div className="crypto-labels-wrapper">
        <div>
          {i18next.t('crypto.select_crypto')}
        </div>
        <div>
          {i18next.t('crypto.amount')}
        </div>
        <div>
          {i18next.t('crypto.address')}
        </div>
      </div>
      <div className="crypto-fields-wrapper">
        <CustomSelect
          items={currencies}
          onSelect={onSelect}
        />
        <div
          className={`crypto-field amount${withdrawalAmount !== '' ? ' not-empty' : ''}`}
          data-currency={getCurrencyMark(playerCurrency)}
        >
          <input
            type="number"
            name="withdrawalAmount"
            placeholder={i18next.t('crypto.amount_placeholder', {
              currency: getCurrencyMark(playerCurrency),
              min: withdrawalLimits.min,
              max: withdrawalLimits.max,
            })}
            value={withdrawalAmount}
            onChange={onChange}
            min={withdrawalLimits.min}
            max={withdrawalLimits.max}
          />
        </div>
        <div className="crypto-field">
          <input
            type="text"
            name="withdrawalAddress"
            value={withdrawalAddress}
            onChange={onChange}
          />
        </div>
        {errorMessage && (
          <p className="is-danger">
            {errorMessage}
          </p>
        )}
        <button
          className={`button${isLoading ? ' is-loading' : ''}`}
          onClick={onRequest}
          disabled={
            withdrawalAddress.trim() === ''
            || Number(withdrawalAmount) < Number(withdrawalLimits.min)
            || Number(withdrawalAmount) > Number(withdrawalLimits.max)
            || withdrawalAmount === ''
          }
        >
          {i18next.t('crypto.request_withdrawal')}
        </button>
      </div>
    </section>
    <section>
      <p className="has-text-weight-normal">
        {i18next.t('crypto.be_careful', { currency: selectedCurrency })}
      </p>
    </section>
  </>
);

CryptoWithdrawal.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  currencies: PropTypes.instanceOf(Array).isRequired,
  withdrawalLimits: PropTypes.instanceOf(Object).isRequired,
  onSelect: PropTypes.func.isRequired,
  withdrawalAmount: PropTypes.string.isRequired,
  withdrawalAddress: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onRequest: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  playerCurrency: PropTypes.string.isRequired,
  selectedCurrency: PropTypes.string.isRequired,
};

CryptoWithdrawal.defaultProps = {
  errorMessage: null,
};

export const Loader = () => (
  <div
    className="loader-wrapper is-active"
  >
    <div className="loader is-loading" />
  </div>
);

export const CryptoBanner = ({ onClick, currencies, isLoading }) => (
  <div
    className="crypto-banner"
  >
    {!isLoading ? (
      <>
        <span>
          {i18next.t('crypto.using_crypto')}
          &nbsp;
          <a href="/#" onClick={onClick}>
            {i18next.t('crypto.click_here')}
          </a>
          &nbsp;
          {i18next.t('crypto.to_proceed')}
        </span>
        <div className="crypto-logos">
          {currencies.map((currency, i) => <img src={`/images/crypto/${currency.toLowerCase()}.svg`} alt="" key={i}/>)}
        </div>
      </>
    ) : <span className="crypto-loading">{`${i18next.t('loading')} crypto...`}</span>}
  </div>
);

CryptoBanner.propTypes = {
  onClick: PropTypes.func.isRequired,
  currencies: PropTypes.instanceOf(Array).isRequired,
  isLoading: PropTypes.bool.isRequired,
};
