import CasinoBase from './base';

class Account extends CasinoBase {
  forgotPasswordStep1And2(parameters) {
    this.response = this.request('get', 'forgotPasswordStep1And2', parameters);

    return this.response;
  }

  forgotPasswordPreStep3(parameters) {
    this.response = this.request('get', 'forgotPasswordPreStep3', parameters);

    return this.response;
  }

  forgotPasswordStep3(parameters) {
    this.response = this.request('get', 'forgotPasswordStep3', parameters);

    return this.response;
  }

  signupConfirmation(parameters) {
    this.response = this.request('get', 'signupConfirmation', parameters);

    return this.response;
  }

  fetchPlayerInfo(playerSessionKey) {
    this.response = this.request('get', 'getPlayerInfo', { sessionKey: playerSessionKey });

    return this.response;
  }

  updatePlayerInfo(parameters) {
    this.response = this.request('post', 'updatePlayerInfo', parameters);

    return this.response;
  }

  updateExtraInfo(parameters) {
    this.response = this.request('get', 'updateExtraInfo', parameters);

    return this.response;
  }

  updatePassword(playerSessionKey, oldPlayerPassword, newPlayerPassword) {
    this.response = this.request('post', 'updatePassword', {
      sessionKey: playerSessionKey, oldPassword: oldPlayerPassword, newPassword: newPlayerPassword,
    });

    return this.response;
  }

  getEmailOptions(playerSessionKey) {
    this.response = this.request('get', 'getEmailOptions', { sessionKey: playerSessionKey });

    return this.response;
  }

  updateEmailOptions(playerSessionKey, playerLangCode, playerSubscription) {
    this.response = this.request('post', 'updateEmailOptions', {
      sessionKey: playerSessionKey, langCode: playerLangCode, subscribe: playerSubscription,
    });

    return this.response;
  }

  getBalanceSimple(playerSessionKey) {
    this.response = this.request('get', 'getBalanceSimple', { sessionKey: playerSessionKey });

    return this.response;
  }

  getBankingHistory(parameters) {
    this.response = this.request('get', 'getBankingHistory', parameters);

    return this.response;
  }

  getTransactionHistory(parameters) {
    this.response = this.request('get', 'getTransactionHistory', parameters);

    return this.response;
  }

  getTransactionHistoryByCurrency(parameters) {
    this.response = this.request('get', 'getTransactionHistoryByCurrency', parameters);

    return this.response;
  }

  getTransactionDetails(parameters) {
    this.response = this.request('get', 'getTransactionDetails', parameters);

    return this.response;
  }

  getRecentlyPlayed(playerSessionKey, recentlyPlayedCount) {
    this.response = this.request('get', 'getRecentlyPlayed', { sessionKey: playerSessionKey, size: recentlyPlayedCount });

    return this.response;
  }

  checkBonusRedemption(playerSessionKey, bonusCode) {
    this.response = this.request('get', 'checkBonusRedemption', { sessionKey: playerSessionKey, bonusCode });

    return this.response;
  }

  getEligibleOptInBonusPlans(playerSessionKey, partyId, type) {
    this.response = this.request('get', 'getEligibleOptInBonusPlans', { sessionKey: playerSessionKey, partyId, type });

    return this.response;
  }

  getActiveBonuses(parameters) {
    this.response = this.request('get', 'getActiveBonuses', parameters);

    return this.response;
  }

  loyaltyPointsStatus(parameters) {
    this.response = this.request('get', 'loyaltyPointsStatus', parameters);

    return this.response;
  }

  getLoyaltyItems(parameters) {
    this.response = this.request('get', 'getLoyaltyItems', parameters);

    return this.response;
  }

  loyaltyShopPurchaseItem(parameters) {
    this.response = this.request('get', 'loyaltyShopPurchaseItem', parameters);

    return this.response;
  }

  redeemBonus(parameters) {
    this.response = this.request('get', 'redeemBonus', parameters);

    return this.response;
  }

  cancelWithdrawal(parameters) {
    this.response = this.request('post', 'cancelWithdrawal', parameters);

    return this.response;
  }

  userConsentsSave(parameters) {
    this.response = this.request('post', 'user/consents/save', parameters);

    return this.response;
  }

  userConsents(parameters) {
    this.response = this.request('get', 'user/consents', parameters);

    return this.response;
  }

  getConsents() {
    this.response = this.request('get', 'consents');

    return this.response;
  }

  getTournamentRanking(parameters) {
    this.response = this.request('get', 'getTournamentRanking', parameters);

    return this.response;
  }

  optInBonus(parameters) {
    this.response = this.request('get', 'optInBonus', parameters);

    return this.response;
  }

  optOutBonus(parameters) {
    this.response = this.request('get', 'optOutBonus', parameters);

    return this.response;
  }

  getFavoriteGames(playerSessionKey) {
    this.response = this.request('get', 'getFavoriteGames', { sessionKey: playerSessionKey, version: 2 });

    return this.response;
  }

  getDepositLimits(parameters) {
    this.response = this.request('get', 'getDepositLimits', parameters);

    return this.response;
  }

  addFavoriteGame(playerSessionKey, gameProvider, gameId) {
    this.response = this.request('get', 'addFavoriteGame', {
      sessionKey: playerSessionKey,
      platformCode: gameProvider,
      gameId,
    });

    return this.response;
  }

  removeFavoriteGame(playerSessionKey, gameProvider, gameId) {
    this.response = this.request('get', 'removeFavoriteGame', {
      sessionKey: playerSessionKey,
      platformCode: gameProvider,
      gameId,
    });

    return this.response;
  }
}

export default Account;
