import LegacyBase from './base';

class Customer extends LegacyBase {
  constructor() {
    super();
    this.modelUrl = 'customers';
  }

  async fetch(url) {
    this.response = await this.request('get', `${this.modelUrl}/${url}`);

    return this.response;
  }

  async update(id, parameters) {
    this.response = await this.request('patch', `${this.modelUrl}/${id}/`, parameters);

    return this.response;
  }

  async validatePassword(parameters) {
    this.response = await this.request('postJson', `${this.modelUrl}/validate-password/`, parameters);

    return this.response;
  }
}

export default Customer;
