import Content from './base';
import SubPagesInfoResponse from '../../rest/content/subPagesInfoResponse';
import Rest from '../../rest';

class SubPagesInfoModel extends Content {
  async fetch(pageId) {
    this.response = await this.request('get', 'pages/', { child_of: pageId, show_in_menus: true });

    return this.response;
  }

  async request(method, path, parameters) {
    const rest = new Rest();
    const request = await rest.request(method, this.endpointFor(path), parameters);

    return new SubPagesInfoResponse(request);
  }
}

export default SubPagesInfoModel;
