import FetchContent from './fetchContent';

class FetchMenu extends FetchContent {
  async run() {
    function formatMenuItems(items) {
      if (typeof items === 'undefined') return [];
      const getItems = items.map((item) => {
        const url = item.page ? new URL(item.page.full_url).pathname : item.url;

        return {
          id: item.id,
          handle: item.handle,
          label: item.label,
          show_when_logged_in: item.page ? item.page.show_when_logged_in : false,
          url,
          icon: item.icon,
        };
      });

      return getItems;
    }

    const response = await this.model.fetch(`menus/${this.url}`);
    response.data.items = formatMenuItems(response.data.top_level_items);
    this.response = response;

    return this.response;
  }
}
export default FetchMenu;
