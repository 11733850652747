import Translate from '../../models/google/translate';
import DB from '../../models/sqlite/db';

class Google {
  constructor() {
    this.target = localStorage.getItem('googleLanguage');
    this.source = 'en';
    this.key = process.env.REACT_APP_GCP_PRIVATE_KEY;
  }

  // Content/Menu
  async Translate(item) {
    const translate = new Translate();
    const db = new DB();
    const origTranslation = item.label;

    const parameters = {
      key: this.key,
      source: this.source,
      target: this.target,
      q: item.label,
    };

    let dbparameters = {
      target: this.target,
      translate: item.label,
    };

    if (this.target !== this.source) {
      const translation = await db.getTranslation(dbparameters);
      if (translation.data[0] === 'No Data!' && item.label !== '') {
        await translate.translateText(parameters).then(async (response) => {
          item.label = await response.data?.translations[0].translatedText;
          dbparameters = {
            target: this.target,
            translation: origTranslation,
            translated: item.label,
          };
          db.setTranslation(dbparameters);
        });
      } else {
        item.label = translation.data[0].translated;
      }
    }
    return item.label;
  }

  // Snippets
  async SnippetsTranslate(item) {
    const translate = new Translate();
    const db = new DB();
    const origTranslation = item.data.text;

    const parameters = {
      key: this.key,
      source: this.source,
      target: this.target,
      q: item.data.text,
    };

    let dbparameters = {
      target: this.target,
      translate: item.data.text,
    };

    if (this.target !== this.source) {
      const translation = await db.getTranslation(dbparameters);
      if (translation.data[0] === 'No Data!' && item.data.text !== '') {
        await translate.translateText(parameters).then(async (response) => {
          item.data.text = await response.data?.translations[0].translatedText;
          dbparameters = {
            target: this.target,
            translation: origTranslation,
            translated: item.data.text,
          };
          db.setTranslation(dbparameters);
        });
      } else {
        item.data.text = translation.data[0].translated;
      }
    }
    return item;
  }

  // Menus
  async ArrayTranslate(items) {
    const translate = new Translate();
    const db = new DB();

    const promises = items.data.items.map(async (item) => {
      if (item.heading !== undefined) {
        const origTranslation = item.heading;
        const parameters = {
          key: this.key,
          source: this.source,
          target: this.target,
          q: item.heading,
        };

        let dbparameters = {
          target: this.target,
          translate: item.heading,
        };

        if (this.target !== this.source) {
          const translation = await db.getTranslation(dbparameters);
          if (translation.data[0] === 'No Data!' && item.heading !== '') {
            await translate.translateText(parameters).then(async (response) => {
              item.heading = response.data?.translations[0].translatedText;
              dbparameters = {
                target: this.target,
                translation: origTranslation,
                translated: item.heading,
              };
              db.setTranslation(dbparameters);
            });
          } else {
            item.heading = translation.data[0].translated;
          }
        }
      }

      if (item.description !== undefined) {
        const origTranslation = item.description;
        const parameters = {
          key: this.key,
          source: this.source,
          target: this.target,
          q: item.description,
        };

        let dbparameters = {
          target: this.target,
          translate: item.description,
        };

        if (this.target !== this.source) {
          const translation = await db.getTranslation(dbparameters);
          if (translation.data[0] === 'No Data!' && item.description !== '') {
            await translate.translateText(parameters).then(async (response) => {
              item.description = response.data?.translations[0].translatedText;
              dbparameters = {
                target: this.target,
                translation: origTranslation,
                translated: item.description,
              };
              db.setTranslation(dbparameters);
            });
          } else {
            item.description = translation.data[0].translated;
          }
        }
      }

      if (item.name !== undefined) {
        const origTranslation = item.name;
        const parameters = {
          key: this.key,
          source: this.source,
          target: this.target,
          q: item.name,
        };

        let dbparameters = {
          target: this.target,
          translate: item.name,
        };

        if (this.target !== this.source) {
          const translation = await db.getTranslation(dbparameters);
          if (translation.data[0] === 'No Data!' && item.name !== '') {
            await translate.translateText(parameters).then(async (response) => {
              item.name = response.data?.translations[0].translatedText;
              dbparameters = {
                target: this.target,
                translation: origTranslation,
                translated: item.name,
              };
              db.setTranslation(dbparameters);
            });
          } else {
            item.name = translation.data[0].translated;
          }
        }
      }

      if (item.title !== undefined) {
        const origTranslation = item.title;
        const parameters = {
          key: this.key,
          source: this.source,
          target: this.target,
          q: item.title,
        };

        let dbparameters = {
          target: this.target,
          translate: item.title,
        };

        if (this.target !== this.source) {
          const translation = await db.getTranslation(dbparameters);
          if (translation.data[0] === 'No Data!' && item.title !== '') {
            await translate.translateText(parameters).then(async (response) => {
              item.title = response.data?.translations[0].translatedText;
              dbparameters = {
                target: this.target,
                translation: origTranslation,
                translated: item.title,
              };
              db.setTranslation(dbparameters);
            });
          } else {
            item.title = translation.data[0].translated;
          }
        }
      }

      if (this.target !== this.source && item.top_level_items !== undefined) {
        item.top_level_items = await this.subArrayTranslate(item.top_level_items);
      }

      return item;
    });
    return Promise.all(promises);
  }

  // GameCategorie
  async GameCategorieTranslate(items) {
    const translate = new Translate();
    const db = new DB();

    const promises = items.data.items.map(async (item) => {
      const origTranslation = item.name;
      const parameters = {
        key: this.key,
        source: this.source,
        target: this.target,
        q: item.name,
      };

      let dbparameters = {
        target: this.target,
        translate: item.name,
      };
      item.name2 = item.name;

      if (this.target !== this.source) {
        const translation = await db.getTranslation(dbparameters);
        if (translation.data[0] === 'No Data!' && item.name2 !== '') {
          await translate.translateText(parameters).then(async (response) => {
            item.name2 = response.data?.translations[0].translatedText;
            dbparameters = {
              target: this.target,
              translation: origTranslation,
              translated: item.name2,
            };
            db.setTranslation(dbparameters);
          });
        } else {
          item.name2 = translation.data[0].translated;
        }
      }
      return item;
    });
    return Promise.all(promises);
  }

  // Articles
  async ArticlesTranslate(items) {
    const translate = new Translate();
    const db = new DB();

    const promises = items.data.items.map(async (item) => {
      const origTranslation = item.excerpt;
      const parameters = {
        key: this.key,
        source: this.source,
        target: this.target,
        q: item.excerpt,
      };

      let dbparameters = {
        target: this.target,
        translate: item.excerpt,
      };

      if (this.target !== this.source) {
        const translation = await db.getTranslation(dbparameters);
        if (translation.data[0] === 'No Data!' && item.excerpt !== '') {
          await translate.translateText(parameters).then(async (response) => {
            item.excerpt = response.data?.translations[0].translatedText;
            dbparameters = {
              target: this.target,
              translation: origTranslation,
              translated: item.excerpt,
            };
            db.setTranslation(dbparameters);
          });
        } else {
          item.excerpt = translation.data[0].translated;
        }
      }
      return item;
    });
    return Promise.all(promises);
  }

  // Pages
  async PageTranslate(items) {
    const translate = new Translate();
    const db = new DB();

    if (this.target === this.source) {
      return items;
    }

    if (items.data.body !== undefined) {
      const origTranslation = items.data.body;
      const parameters = {
        key: this.key,
        source: this.source,
        target: this.target,
        q: items.data.body,
      };

      let dbparameters = {
        target: this.target,
        translate: items.data.body,
      };

      const translation = await db.getTranslation(dbparameters);
      if (translation.data[0] === 'No Data!' && items.data.body !== '') {
        await translate.translateText(parameters).then(async (response) => {
          items.data.body = await response.data?.translations[0].translatedText;
          dbparameters = {
            target: this.target,
            translation: origTranslation,
            translated: items.data.body,
          };
          db.setTranslation(dbparameters);
        });
      } else {
        items.data.body = translation.data[0].translated;
      }
    }

    if (items.data.excerpt !== undefined) {
      const origTranslation = items.data.excerpt;
      const parameters = {
        key: this.key,
        source: this.source,
        target: this.target,
        q: items.data.excerpt,
      };

      let dbparameters = {
        target: this.target,
        translate: items.data.excerpt,
      };

      const translation = await db.getTranslation(dbparameters);
      if (translation.data[0] === 'No Data!' && items.data.excerpt !== '') {
        await translate.translateText(parameters).then(async (response) => {
          items.data.excerpt = await response.data?.translations[0].translatedText;
          dbparameters = {
            target: this.target,
            translation: origTranslation,
            translated: items.data.excerpt,
          };
          db.setTranslation(dbparameters);
        });
      } else {
        items.data.excerpt = translation.data[0].translated;
      }
    }

    if (items.data.benefits_description !== undefined) {
      const origTranslation = items.data.benefits_description;
      const parameters = {
        key: this.key,
        source: this.source,
        target: this.target,
        q: items.data.benefits_description,
      };

      let dbparameters = {
        target: this.target,
        translate: items.data.benefits_description,
      };

      const translation = await db.getTranslation(dbparameters);
      if (translation.data[0] === 'No Data!' && items.data.benefits_description !== '') {
        await translate.translateText(parameters).then(async (response) => {
          items.data.benefits_description = await response.data?.translations[0].translatedText;
          dbparameters = {
            target: this.target,
            translation: origTranslation,
            translated: items.data.benefits_description,
          };
          db.setTranslation(dbparameters);
        });
      } else {
        items.data.benefits_description = translation.data[0].translated;
      }
    }

    if (items.data.benefits_title !== undefined) {
      const origTranslation = items.data.benefits_title;
      const parameters = {
        key: this.key,
        source: this.source,
        target: this.target,
        q: items.data.benefits_title,
      };

      let dbparameters = {
        target: this.target,
        translate: items.data.benefits_title,
      };

      const translation = await db.getTranslation(dbparameters);
      if (translation.data[0] === 'No Data!' && items.data.benefits_title !== '') {
        await translate.translateText(parameters).then(async (response) => {
          items.data.benefits_title = await response.data?.translations[0].translatedText;
          dbparameters = {
            target: this.target,
            translation: origTranslation,
            translated: items.data.benefits_title,
          };
          db.setTranslation(dbparameters);
        });
      } else {
        items.data.benefits_title = translation.data[0].translated;
      }
    }

    if (items.data.description !== undefined) {
      const origTranslation = items.data.description;
      const parameters = {
        key: this.key,
        source: this.source,
        target: this.target,
        q: items.data.description,
      };

      let dbparameters = {
        target: this.target,
        translate: items.data.description,
      };

      const translation = await db.getTranslation(dbparameters);
      if (translation.data[0] === 'No Data!' && items.data.description !== '') {
        await translate.translateText(parameters).then(async (response) => {
          items.data.description = await response.data?.translations[0].translatedText;
          dbparameters = {
            target: this.target,
            translation: origTranslation,
            translated: items.data.description,
          };
          db.setTranslation(dbparameters);
        });
      } else {
        items.data.description = translation.data[0].translated;
      }
    }

    if (items.data.levels !== undefined) {
      const origTranslation = items.data.levels;
      const parameters = {
        key: this.key,
        source: this.source,
        target: this.target,
        q: items.data.levels,
      };

      let dbparameters = {
        target: this.target,
        translate: items.data.levels,
      };

      const translation = await db.getTranslation(dbparameters);
      if (translation.data[0] === 'No Data!' && items.data.levels !== '') {
        await translate.translateText(parameters).then(async (response) => {
          items.data.levels = await response.data?.translations[0].translatedText;
          dbparameters = {
            target: this.target,
            translation: origTranslation,
            translated: items.data.levels,
          };
          db.setTranslation(dbparameters);
        });
      } else {
        items.data.levels = translation.data[0].translated;
      }
    }

    if (items.data.title !== undefined) {
      const origTranslation = items.data.title;
      const parameters = {
        key: this.key,
        source: this.source,
        target: this.target,
        q: items.data.title,
      };

      let dbparameters = {
        target: this.target,
        translate: items.data.title,
      };

      const translation = await db.getTranslation(dbparameters);
      if (translation.data[0] === 'No Data!' && items.data.title !== '') {
        await translate.translateText(parameters).then(async (response) => {
          items.data.title = await response.data?.translations[0].translatedText;
          dbparameters = {
            target: this.target,
            translation: origTranslation,
            translated: items.data.title,
          };
          db.setTranslation(dbparameters);
        });
      } else {
        items.data.title = translation.data[0].translated;
      }
    }

    if (items.data.seo.title !== undefined) {
      const origTranslation = items.data.seo.title;
      const parameters = {
        key: this.key,
        source: this.source,
        target: this.target,
        q: items.data.seo.title,
      };

      let dbparameters = {
        target: this.target,
        translate: items.data.seo.title,
      };

      const translation = await db.getTranslation(dbparameters);
      if (translation.data[0] === 'No Data!' && items.data.seo.title !== '') {
        await translate.translateText(parameters).then(async (response) => {
          items.data.seo.title = await response.data?.translations[0].translatedText;
          dbparameters = {
            target: this.target,
            translation: origTranslation,
            translated: items.data.seo.title,
          };
          db.setTranslation(dbparameters);
        });
      } else {
        items.data.seo.title = translation.data[0].translated;
      }
    }

    if (items.data.seo.body !== undefined) {
      const origTranslation = items.data.seo.body;
      const parameters = {
        key: this.key,
        source: this.source,
        target: this.target,
        q: items.data.seo.body,
      };

      let dbparameters = {
        target: this.target,
        translate: items.data.seo.body,
      };

      const translation = await db.getTranslation(dbparameters);
      if (translation.data[0] === 'No Data!' && items.data.seo.body !== '') {
        await translate.translateText(parameters).then(async (response) => {
          items.data.seo.body = await response.data?.translations[0].translatedText;
          dbparameters = {
            target: this.target,
            translation: origTranslation,
            translated: items.data.seo.body,
          };
          db.setTranslation(dbparameters);
        });
      } else {
        items.data.seo.body = translation.data[0].translated;
      }
    }

    if (items.data.benefits !== undefined && items.data.benefits.length > 0) {
      items.data.benefits = await this.subArrayTranslate(items.data.benefits);
    }

    if (items.data.categories !== undefined && items.data.categories.length > 0) {
      items.data.categories = await this.subArrayTranslate(items.data.categories);
    }

    if (items.data.level_details !== undefined && items.data.level_details.length > 0) {
      items.data.level_details = await this.subArrayTranslate(items.data.level_details);
    }

    if (items.data.sections !== undefined && items.data.sections.length > 0) {
      items.data.sections = await this.SectionsTranslate(items.data.sections);
    }

    if (items.data.carousel !== undefined && items.data.carousel.length > 0) {
      items.data.carousel = await this.subArrayTranslate(items.data.carousel);
    }

    if (items.data.questions_and_answers !== undefined && items.data.questions_and_answers.length > 0) {
      items.data.questions_and_answers = await this.subArrayTranslate(items.data.questions_and_answers);
    }
    return items;
  }

  // Sections
  async SectionsTranslate(items) {
    const translate = new Translate();
    const db = new DB();

    if (this.target === this.source) {
      return items;
    }

    const promises = items.map(async (item) => {
      if (item.description !== undefined) {
        const origTranslation = item.description;
        const parameters = {
          key: this.key,
          source: this.source,
          target: this.target,
          q: item.description,
        };

        let dbparameters = {
          target: this.target,
          translate: item.description,
        };
        const translation = await db.getTranslation(dbparameters);
        if (translation.data[0] === 'No Data!' && item.description !== '') {
          await translate.translateText(parameters).then(async (response) => {
            item.description = await response.data?.translations[0].translatedText;
            dbparameters = {
              target: this.target,
              translation: origTranslation,
              translated: item.description,
            };
            db.setTranslation(dbparameters);
          });
        } else {
          item.description = translation.data[0].translated;
        }
      }

      if (item.description_logged_out !== undefined) {
        const origTranslation = item.description_logged_out;
        const parameters = {
          key: this.key,
          source: this.source,
          target: this.target,
          q: item.description_logged_out,
        };

        let dbparameters = {
          target: this.target,
          translate: item.description_logged_out,
        };
        const translation = await db.getTranslation(dbparameters);
        if (translation.data[0] === 'No Data!' && item.description_logged_out !== '') {
          await translate.translateText(parameters).then(async (response) => {
            item.description_logged_out = await response.data?.translations[0].translatedText;
            dbparameters = {
              target: this.target,
              translation: origTranslation,
              translated: item.description_logged_out,
            };
            db.setTranslation(dbparameters);
          });
        } else {
          item.description_logged_out = translation.data[0].translated;
        }
      }

      if (item.button_label !== undefined) {
        const origTranslation = item.button_label;
        const parameters = {
          key: this.key,
          source: this.source,
          target: this.target,
          q: item.button_label,
        };

        let dbparameters = {
          target: this.target,
          translate: item.button_label,
        };

        const translation = await db.getTranslation(dbparameters);
        if (translation.data[0] === 'No Data!' && item.button_label !== '') {
          await translate.translateText(parameters).then(async (response) => {
            item.button_label = await response.data?.translations[0].translatedText;
            dbparameters = {
              target: this.target,
              translation: origTranslation,
              translated: items.button_label,
            };
            db.setTranslation(dbparameters);
          });
        } else {
          item.button_label = translation.data[0].translated;
        }
      }

      if (item.title !== undefined) {
        const origTranslation = item.title;
        const parameters = {
          key: this.key,
          source: this.source,
          target: this.target,
          q: item.title,
        };

        let dbparameters = {
          target: this.target,
          translate: item.title,
        };
        const translation = await db.getTranslation(dbparameters);
        if (translation.data[0] === 'No Data!' && item.title !== '') {
          await translate.translateText(parameters).then(async (response) => {
            item.title = await response.data?.translations[0].translatedText;
            dbparameters = {
              target: this.target,
              translation: origTranslation,
              translated: item.title,
            };
            db.setTranslation(dbparameters);
          });
        } else {
          item.title = translation.data[0].translated;
        }
        return item;
      }
    });
    return Promise.all(promises);
  }

  // SubArray
  async subArrayTranslate(items) {
    const translate = new Translate();
    const db = new DB();

    if (this.target === this.source) {
      return items;
    }

    const promises = items.map(async (item) => {
      if (item.label !== undefined) {
        const origTranslation = item.label;
        const parameters = {
          key: this.key,
          source: this.source,
          target: this.target,
          q: item.label,
        };

        let dbparameters = {
          target: this.target,
          translate: item.label,
        };
        const translation = await db.getTranslation(dbparameters);
        if (translation.data[0] === 'No Data!' && item.label !== '') {
          await translate.translateText(parameters).then(async (response) => {
            item.label = await response.data?.translations[0].translatedText;
            dbparameters = {
              target: this.target,
              translation: origTranslation,
              translated: item.label,
            };
            db.setTranslation(dbparameters);
          });
        } else {
          item.label = translation.data[0].translated;
        }
      }

      if (item.heading !== undefined) {
        const origTranslation = item.heading;
        const parameters = {
          key: this.key,
          source: this.source,
          target: this.target,
          q: item.heading,
        };

        let dbparameters = {
          target: this.target,
          translate: item.heading,
        };
        const translation = await db.getTranslation(dbparameters);
        if (translation.data[0] === 'No Data!' && item.heading !== '') {
          await translate.translateText(parameters).then(async (response) => {
            item.heading = await response.data?.translations[0].translatedText;
            dbparameters = {
              target: this.target,
              translation: origTranslation,
              translated: item.heading,
            };
            db.setTranslation(dbparameters);
          });
        } else {
          item.heading = translation.data[0].translated;
        }
      }

      if (item.description !== undefined) {
        const origTranslation = item.description;
        const parameters = {
          key: this.key,
          source: this.source,
          target: this.target,
          q: item.description,
        };

        let dbparameters = {
          target: this.target,
          translate: item.description,
        };
        const translation = await db.getTranslation(dbparameters);
        if (translation.data[0] === 'No Data!' && item.description !== '') {
          await translate.translateText(parameters).then(async (response) => {
            item.description = await response.data?.translations[0].translatedText;
            dbparameters = {
              target: this.target,
              translation: origTranslation,
              translated: item.description,
            };
            db.setTranslation(dbparameters);
          });
        } else {
          item.description = translation.data[0].translated;
        }
      }

      if (item.title !== undefined) {
        const origTranslation = item.title;
        const parameters = {
          key: this.key,
          source: this.source,
          target: this.target,
          q: item.title,
        };

        let dbparameters = {
          target: this.target,
          translate: item.title,
        };
        const translation = await db.getTranslation(dbparameters);
        if (translation.data[0] === 'No Data!' && item.title !== '') {
          await translate.translateText(parameters).then(async (response) => {
            item.title = await response.data?.translations[0].translatedText;
            dbparameters = {
              target: this.target,
              translation: origTranslation,
              translated: item.title,
            };
            db.setTranslation(dbparameters);
          });
        } else {
          item.title = translation.data[0].translated;
        }
      }

      if (item.subtitle !== undefined) {
        const origTranslation = item.subtitle;
        const parameters = {
          key: this.key,
          source: this.source,
          target: this.target,
          q: item.subtitle,
        };

        let dbparameters = {
          target: this.target,
          translate: item.subtitle,
        };
        const translation = await db.getTranslation(dbparameters);
        if (translation.data[0] === 'No Data!' && item.subtitle !== '') {
          await translate.translateText(parameters).then(async (response) => {
            item.subtitle = await response.data?.translations[0].translatedText;
            dbparameters = {
              target: this.target,
              translation: origTranslation,
              translated: item.subtitle,
            };
            db.setTranslation(dbparameters);
          });
        } else {
          item.subtitle = translation.data[0].translated;
        }
      }

      if (item.sub_title !== undefined) {
        const origTranslation = item.sub_title;
        const parameters = {
          key: this.key,
          source: this.source,
          target: this.target,
          q: item.sub_title,
        };

        let dbparameters = {
          target: this.target,
          translate: item.sub_title,
        };
        const translation = await db.getTranslation(dbparameters);
        if (translation.data[0] === 'No Data!' && item.sub_title !== '') {
          await translate.translateText(parameters).then(async (response) => {
            item.sub_title = await response.data?.translations[0].translatedText;
            dbparameters = {
              target: this.target,
              translation: origTranslation,
              translated: item.sub_title,
            };
            db.setTranslation(dbparameters);
          });
        } else {
          item.sub_title = translation.data[0].translated;
        }
      }

      if (item.level !== undefined) {
        const origTranslation = item.level;
        const parameters = {
          key: this.key,
          source: this.source,
          target: this.target,
          q: item.level,
        };

        let dbparameters = {
          target: this.target,
          translate: item.level,
        };
        const translation = await db.getTranslation(dbparameters);
        if (translation.data[0] === 'No Data!' && item.level !== '') {
          await translate.translateText(parameters).then(async (response) => {
            item.level = await response.data?.translations[0].translatedText;
            dbparameters = {
              target: this.target,
              translation: origTranslation,
              translated: item.level,
            };
            db.setTranslation(dbparameters);
          });
        } else {
          item.level = translation.data[0].translated;
        }
      }

      if (item.caption !== undefined) {
        const origTranslation = item.caption;
        const parameters = {
          key: this.key,
          source: this.source,
          target: this.target,
          q: item.caption,
        };

        let dbparameters = {
          target: this.target,
          translate: item.caption,
        };
        const translation = await db.getTranslation(dbparameters);
        if (translation.data[0] === 'No Data!' && item.caption !== '') {
          await translate.translateText(parameters).then(async (response) => {
            item.caption = await response.data?.translations[0].translatedText;
            dbparameters = {
              target: this.target,
              translation: origTranslation,
              translated: item.caption,
            };
            db.setTranslation(dbparameters);
          });
        } else {
          item.caption = translation.data[0].translated;
        }
      }

      if (item.button_label !== undefined) {
        const origTranslation = item.button_label;
        const parameters = {
          key: this.key,
          source: this.source,
          target: this.target,
          q: item.button_label,
        };

        let dbparameters = {
          target: this.target,
          translate: item.button_label,
        };
        const translation = await db.getTranslation(dbparameters);
        if (translation.data[0] === 'No Data!' && item.button_label !== '') {
          await translate.translateText(parameters).then(async (response) => {
            item.button_label = await response.data?.translations[0].translatedText;
            dbparameters = {
              target: this.target,
              translation: origTranslation,
              translated: item.button_label,
            };
            db.setTranslation(dbparameters);
          });
        } else {
          item.button_label = translation.data[0].translated;
        }
      }

      if (item.name !== undefined) {
        const origTranslation = item.name;
        const parameters = {
          key: this.key,
          source: this.source,
          target: this.target,
          q: item.name,
        };

        let dbparameters = {
          target: this.target,
          translate: item.name,
        };
        const translation = await db.getTranslation(dbparameters);
        if (translation.data[0] === 'No Data!' && item.name !== '') {
          await translate.translateText(parameters).then(async (response) => {
            item.name = await response.data?.translations[0].translatedText;
            dbparameters = {
              target: this.target,
              translation: origTranslation,
              translated: item.name,
            };
            db.setTranslation(dbparameters);
          });
        } else {
          item.name = translation.data[0].translated;
        }
      }

      if (item.question !== undefined) {
        const origTranslation = item.question;
        const parameters = {
          key: this.key,
          source: this.source,
          target: this.target,
          q: item.question,
        };

        let dbparameters = {
          target: this.target,
          translate: item.question,
        };
        const translation = await db.getTranslation(dbparameters);
        if (translation.data[0] === 'No Data!' && item.question !== '') {
          await translate.translateText(parameters).then(async (response) => {
            item.question = await response.data?.translations[0].translatedText;
            dbparameters = {
              target: this.target,
              translation: origTranslation,
              translated: item.question,
            };
            db.setTranslation(dbparameters);
          });
        } else {
          item.question = translation.data[0].translated;
        }
      }

      if (item.answer !== undefined) {
        const origTranslation = item.answer;
        const parameters = {
          key: this.key,
          source: this.source,
          target: this.target,
          q: item.answer,
        };

        let dbparameters = {
          target: this.target,
          translate: item.answer,
        };
        const translation = await db.getTranslation(dbparameters);
        if (translation.data[0] === 'No Data!' && item.answer !== '') {
          await translate.translateText(parameters).then(async (response) => {
            item.answer = await response.data?.translations[0].translatedText;
            dbparameters = {
              target: this.target,
              translation: origTranslation,
              translated: item.answer,
            };
            db.setTranslation(dbparameters);
          });
        } else {
          item.answer = translation.data[0].translated;
        }
      }

      if (item.questions !== undefined && item.questions.length > 0) {
        item.questions = await this.subArrayTranslate(item.questions);
      }
      return item;
    });
    return Promise.all(promises);
  }

  // SingleTranslate
  async SingleTranslate(item) {
    const translate = new Translate();
    const db = new DB();

    const origTranslation = item;

    const parameters = {
      key: this.key,
      source: this.source,
      target: this.target,
      q: item,
    };

    let dbparameters = {
      target: this.target,
      translate: item,
    };

    if (this.target !== this.source) {
      const translation = await db.getTranslation(dbparameters);
      if (translation.data[0] === 'No Data!' && item !== '') {
        await translate.translateText(parameters).then(async (response) => {
          item = await response.data?.translations[0].translatedText;
          dbparameters = {
            target: this.target,
            translation: origTranslation,
            translated: item,
          };
          db.setTranslation(dbparameters);
        });
      } else {
        item = translation.data[0].translated;
      }
    }
    return item;
  }
  // End
}
export default Google;
