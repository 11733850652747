import Service from '../../service';
import Game from '../../models/casino/game';
import FetchContent from '../content/fetchContent';

class GetWinners extends Service {
  constructor(parameters) {
    super(parameters);
    this.parameters = parameters;
  }

  async run() {
    await this.getGameWinnerList();
  }

  async getGameWinnerList() {
    let url = 'games/?reference__in=';
    let launchIds = '';

    try {
      const { data } = await new Game().getGameWinnerList(this.parameters);
      const { status, winners } = data;

      if (status === 'SUCCESS') {
        launchIds = winners.reduce((arr, game) => arr.concat(game.gameLaunchId), []).join();

        url += launchIds;

        const games = await new FetchContent(url).perform();

        const winnerListWithThumbnails = winners.map((game) => {
          games.items().forEach((item) => {
            if (game.gameLaunchId === item.reference) {
              Object.assign(game, { thumbnail: item.thumbnail, slug: item.slug });
            }
          });

          return game;
        });

        super.response = !games.length ? winners : winnerListWithThumbnails;
      } else {
        super.response = [];
      }
    } catch (error) {
      super.response = [];
    }
  }
}

export default GetWinners;
