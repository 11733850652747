import CasinoBase from './base';

class signupConfirmation extends CasinoBase {
  constructor(parameters) {
    super();
    this.parameters = parameters;
  }

  async create() {
    this.response = await this.request('get', 'signupConfirmation', this.parameters);

    return this.response;
  }
}
export default signupConfirmation;
