import React from 'react';
import PropTypes from 'prop-types';
import userReducer, { initialState } from '../hooks/reducer/userReducer';

export const UserStateContext = React.createContext();

export const UserDispatchContext = React.createContext();

export const UserContextProvider = ({
  user = initialState,
  children,
}) => {
  // istanbul ignore next
  const [state, dispatch] = React.useReducer(userReducer, user);

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>{children}</UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
};

UserContextProvider.propTypes = {
  user: PropTypes.instanceOf(Object).isRequired,
  children: PropTypes.node.isRequired,
};
