/* eslint-disable no-plusplus */
/* istanbul ignore file */
import React, { useEffect } from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import Axios from 'axios';
import Signup from '../../../models/casino/signup';
import CasinoBase from '../../../models/casino/base';
import Country from '../../../models/casino/country';
import Cookie from '../../../cookie';
import Account from '../../../models/casino/account';
import Signin from '../../../services/signin';
import AmlCheck from '../../../services/amlCheck';
import withUserHOC from '../../userHOC';
import allCountries from './allCountries';
import Currency from '../../../models/casino/currency';
import FetchContent from '../../../services/content/fetchContent';
import currenciesCsv from '../../../services/country-currency.json';

const SignUpModal = (props) => {
  const [consents, setConsents] = React.useState([]);
  const [country, setCountry] = React.useState({
    countryCode: '',
    restricted: false,
    currencies: [],
    currenciesByCountries: [],
    allRestrictedCountries: [],
  });

  const {
    isOpen,
    t,
    onToggleModal,
    onGeneralErrorToggleModal,
    setUserLoggedInAs,
    updateUser,
  } = props;

  const url = `${process.env.REACT_APP_API_OMEGA_URL}signup`;

  const restrictedCountriesFromENV = process.env.REACT_APP_REG_RESTRICTED_COUNTRIES.split(',');

  const getConsents = async () => {
    try {
      const userConsents = await new Account().getConsents();
      const consentsArray = userConsents?.data?.consents;

      if (consentsArray?.length) {
        const formatted = consentsArray.reduce(
          (arr, acc) => ({ ...arr, [acc.key]: acc.consentVersionId }),
          {},
        );
        setConsents([formatted]);
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const setLanguage = () => {
    const checkinAvailableLanguages = [
      'sv',
      'en',
      'da',
      'nl',
      'de',
      'fi',
      'no',
      'pl',
      'ja',
      'fr',
      'es',
      'it',
      'tr',
      'pt',
      'zh',
      'en-US',
      'ru',
      'ro',
      'th',
    ];
    const { language } = i18next;

    return checkinAvailableLanguages.find(item => item === language.split('-')[0]) || 'en';
  };

  const resetSessionAtStart = () => {
    window.checkin.settings.resetSessionOnStart();
  };

  const getEnabledCountries = async () => {
    try {
      const enabledCountries = await new Country().all();
      return enabledCountries;
    } catch (err) {
      throw new Error(err);
    }
  };

  const getAllRestrictedCountries = async () => {
    const { data } = await getEnabledCountries();

    const restricted = allCountries.filter(item => !data?.countries.includes(item));
    const extendedList = restricted.concat(restrictedCountriesFromENV);

    return extendedList;
  };

  const getCountryInfo = async () => {
    try {
      const { data } = await new Country().getCountryInfo();
      const {
        countryInfo: { iso2Code },
      } = data;
      return iso2Code;
    } catch (err) {
      throw new Error(err);
    }
  };

  const getCurrenciesAndCountries = (currenciesFromOmega, countryList) => {
    const result = currenciesFromOmega.reduce((acc, item) => {
      const countries = countryList
        .filter(c => c.Currency.includes(item.value))
        .map(c => c.ISO);

      return [...acc, { currency: item.value, countries }];
    }, []);

    const filtered = result.filter(currency => currency.countries.length);

    return filtered;
  };

  const overrideCurrenciesBasedOnCMS = (currenciesFromCms) => {
    const currenciesCsvCopy = JSON.parse(JSON.stringify(currenciesCsv));
    const overrided = [];

    if (currenciesFromCms.length) {
      for (let i = 0; i < currenciesCsvCopy.length; i++) {
        for (let j = 0; j < currenciesFromCms.length; j++) {
          if (currenciesCsvCopy[i].ISO === currenciesFromCms[j].country) {
            currenciesCsvCopy[i].Currency = currenciesFromCms[j].currencies;
          }
        }
        overrided.push(currenciesCsvCopy[i]);
      }
    }

    return overrided.length ? overrided : currenciesFromCms;
  };

  const getCurrencies = async () => {
    try {
      const currenciesByOmega = await new Currency().all();
      const siteSetting = await new FetchContent('site-settings/').perform();
      const currenciesByCms = siteSetting?.data?.items[0]?.country_currency;
      const overridedCSV = overrideCurrenciesBasedOnCMS(currenciesByCms);

      const currenciesAndCountries = getCurrenciesAndCountries(currenciesByOmega, overridedCSV);

      return currenciesAndCountries;
    } catch (err) {
      throw new Error(err);
    }
  };

  const setContryAndCurrency = async () => {
    try {
      const countryCode = await getCountryInfo();
      const currencies = await getCurrencies(countryCode);
      const allRestrictedCountries = await getAllRestrictedCountries();
      const restricted = allRestrictedCountries.includes(countryCode);

      setCountry(prevState => ({
        ...prevState,
        countryCode,
        restricted,
        currencies,
        allRestrictedCountries,
      }));
    } catch (err) {
      throw new Error(err);
    }
  };

  const formatPhoneNumberToOmega = phoneNumber => phoneNumber.substring(1);

  const checkError = (result) => {
    const inputError = {};

    if (result) {
      if (result.data.errors) {
        result.data.errors.forEach(({ field, error }) => {
          if (error === 'DUPLICATE') inputError[field] = error;
        });
      }

      if (result.data.exists && result.data.exists === 'true') inputError.phone = 'DUPLICATE';
    }

    return inputError;
  };

  const setErrorMessage = (title, message, type, closeFlow = false) => window
    .checkin.generate.dataError.custom({
      title,
      message,
      type,
      closeFlow,
    });

  const showErrorMessage = (errors) => {
    const {
      email: emailError, username: usernameError, phone: phoneError, geoIpError,
    } = errors;

    if (emailError) return setErrorMessage(t('errors:email_fail'), t('errors:email_fail_message'), 'Email');
    if (usernameError) return window.checkin.generate.dataError.duplicateUsername();
    if (phoneError) return setErrorMessage(t('errors:phone_fail'), t('errors:phone_fail_message'), 'duplicatePhoneNumber');
    if (geoIpError) return setErrorMessage(t('errors:geo_ip_failed_title'), t('errors:geo_ip_failed_subtitle'), 'GeoIP', true);

    return null;
  };

  const checkCredentialsInOmega = async (parameters, onComplateRegistration = false) => {
    const { credentials, user } = parameters;
    let result;

    if (credentials && !onComplateRegistration) {
      if (credentials.email || credentials.username) {
        result = await new Signup().signup(parameters.credentials);
      }
    }

    if (user || onComplateRegistration) {
      if (user.phone) {
        const phoneParameter = {
          field: 'mobilePhone',
          value: formatPhoneNumberToOmega(parameters.user.phoneE164),
        };

        result = await new CasinoBase().request('get', 'checkCredential', phoneParameter);
      }
    }

    return result;
  };

  const handlePossibleErrors = async (data, dataDiff) => {
    const result = await checkCredentialsInOmega(dataDiff);

    const inputErrors = checkError(result);

    const { email, username, phone } = inputErrors;

    if (email || username || phone) return showErrorMessage(inputErrors, data);
    return null;
  };

  const addTrackingValue = (playerObj) => {
    const playerData = { ...playerObj };
    if (document.cookie.indexOf(process.env.REACT_APP_EGASS_COOKIE_NAME) >= 0) {
      playerData.userTrackingCodes = new Cookie().get(process.env.REACT_APP_EGASS_COOKIE_NAME);
    }

    return playerData;
  };

  const extendPlayerData = (playerObj) => {
    const extendedPlayerObj = { ...playerObj };

    extendedPlayerObj.securityQuestion = 'What were the last 4 digits of your childhood telephone number?';
    extendedPlayerObj.securityAnswer = '1234';
    extendedPlayerObj.extraInfo = '{"SourceOfWealth": ""}';

    return addTrackingValue(extendedPlayerObj);
  };

  const onInputChange = () => {
    window.checkin.dataFlow.setOnUpdate(async (data, dataDiff) => {
      if (
        (dataDiff.credentials && (dataDiff.credentials.email || dataDiff.credentials.username))
        || (dataDiff.user && dataDiff.user.phone)
      ) {
        if (country.restricted) return showErrorMessage({ geoIpError: true });
        // eslint-disable-next-line no-return-await
        return await handlePossibleErrors(data, dataDiff);
      }
      return null;
    });
  };

  const callbackEvent = () => {
    Object.defineProperty(window, 'regilyEventCallback', {
      value(data) {
        if (data.action === 'close-module') onToggleModal();
        return data;
      },
      configurable: true,
    });
  };

  const getUserConsents = (playerData) => {
    const playerConsents = {
      consentedVersions: [],
      unconsentedVersions: [],
    };
    const [consent] = consents;

    // eslint-disable-next-line array-callback-return
    Object.entries(playerData.consent).map(([key, value]) => {
      let versionId = null;

      if (key === 'terms') versionId = consent?.TERMS_AND_CONDITIONS;
      if (key === 'privacy') versionId = consent?.PRIVACY_POLICY;
      if (key === 'marketing') versionId = [consent?.EMAIL, consent?.SMS];
      if (key === 'promotions') versionId = consent?.EMAIL;
      if (key === 'bonuses') versionId = consent?.SMS;
      if (key === 'age') return;

      if (value.consent) {
        playerConsents.consentedVersions.push(versionId);
      } else {
        playerConsents.unconsentedVersions.push(versionId);
      }
    });

    const flattenPlayerConsents = {
      consentedVersions: playerConsents.consentedVersions.flat(),
      unconsentedVersions: playerConsents.unconsentedVersions.flat(),
    };

    return flattenPlayerConsents;
  };

  const sendUserConsents = async (sessionKey, playerData) => {
    const { consentedVersions, unconsentedVersions } = getUserConsents(playerData);

    if (!consentedVersions.length && unconsentedVersions.length) return;

    try {
      if (consentedVersions.length) {
        consentedVersions.map(consentId => new Account()
          .userConsentsSave({ sessionKey, consentedVersions: consentId }));
      }

      if (unconsentedVersions.length) {
        unconsentedVersions.map(consentId => new Account()
          .userConsentsSave({ sessionKey, unConsentedVersions: consentId }));
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const formatDataForOmega = ({ data, completeData }) => {
    const playerConsents = getUserConsents(completeData);

    const playerData = {
      ...data,
      ...playerConsents, // consentedVersions, unconsentedVersions
      mobile: formatPhoneNumberToOmega(completeData.user.phoneE164),
      currency: data.currency.toUpperCase(),
      consent: completeData.consent, // this is for autologin
    };

    const allPlayerData = extendPlayerData(playerData);

    return allPlayerData;
  };

  const amlCheck = async (playerData) => {
    const {
      username, firstName, lastName, birthDate, country: playerCountry,
    } = playerData;

    const playerDetails = {
      id: username,
      given_name: firstName,
      surname: lastName,
      birthdate: birthDate,
      country: playerCountry,
      site_name: process.env.REACT_APP_BRAND.toLowerCase(),
    };

    try {
      await new AmlCheck(playerDetails).perform();
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const autoLogin = async (playerData) => {
    const { username, password, consent } = playerData;

    const userConsents = {
      tac: true,
      pp: true,
    };

    const receiveEmails = 'marketing' in consent
      ? consent.marketing.consent
      : consent.promotions.consent;

    try {
      const result = await new Signin(username, password, null, userConsents).perform();
      const { data: { sessionKey, email } } = result;
      if (result.success()) {
        await sendUserConsents(sessionKey, playerData);

        updateUser({
          email,
          tac: consent.terms.consent,
          pp: consent.privacy.consent,
          rec_emails: receiveEmails,
          sessionKey,
          sourceOfWealth: '',
        });
        setUserLoggedInAs(true);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const initialize = () => {
    window.checkin.settings.setLang(setLanguage());
    window.checkin.settings.countries.setRestricted(country.allRestrictedCountries);
    window.checkin.settings.currencies.setSelectableByCountry(country.currencies);
    window.checkin.settings.enableOTP();
  };

  const makeQueryParams = (playerData) => {
    const params = new URLSearchParams();

    // eslint-disable-next-line object-curly-newline, max-len
    const { consentedVersions, unconsentedVersions, state, stateCode, recaptchaData, ...rest } = playerData;

    Object.entries(rest).forEach(([key, value]) => {
      params.append(key, value);
    });

    if (consentedVersions?.length) {
      consentedVersions.forEach((consentId) => {
        params.append('consentedVersions', consentId);
      });
    }

    if (unconsentedVersions?.length) {
      unconsentedVersions.forEach((consentId) => {
        params.append('unconsentedVersions', consentId);
      });
    }

    return params;
  };

  const onComplateRegistration = () => {
    window.checkin.dataFlow.setOnComplete(async (data) => {
      const finalPlayerData = formatDataForOmega(data);

      const params = makeQueryParams(finalPlayerData);

      try {
        const result = await Axios.get(url, { params });

        if (result.data.status === 'SUCCESS') {
          amlCheck(finalPlayerData);

          if (process.env.REACT_APP_REGISTRATION_AUTO_LOGIN === 'true') autoLogin(finalPlayerData);
        } else {
          return setErrorMessage(
            t('errors:account_not_created'),
            t('errors:something_went_wrong'),
            'REG_FAIL',
            true,
          );
        }
      } catch (error) {
        return setErrorMessage(
          t('errors:account_not_created'),
          t('errors:something_went_wrong'),
          'REG_FAIL',
          true,
        );
      }
      return null;
    });
  };

  const openErrorModal = () => {
    onGeneralErrorToggleModal(t('errors:no_players_from_your_country'));
  };

  const openSignupModal = () => {
    window.checkin.signUp.open();
  };

  const registerEvents = () => {
    callbackEvent();
    onInputChange();
    onComplateRegistration();
  };

  useEffect(() => {
    resetSessionAtStart();
    setContryAndCurrency();
    getConsents();
  }, []);

  useEffect(() => {
    if (isOpen && country.countryCode && consents.length) {
      initialize();
      registerEvents();

      if (country.restricted) {
        openErrorModal();
      } else {
        openSignupModal();
      }
    }
  }, [country, isOpen]);

  return null;
};

export default withUserHOC(SignUpModal);

SignUpModal.propTypes = {
  isOpen: PropTypes.bool,
  t: PropTypes.func.isRequired,
  onToggleModal: PropTypes.func.isRequired,
  onGeneralErrorToggleModal: PropTypes.func.isRequired,
  setUserLoggedInAs: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
};

SignUpModal.defaultProps = {
  isOpen: false,
};
