import Service from '../../service';
import Content from '../../models/content/base';

class FetchAny extends Service {
  constructor(url) {
    super();
    this.model = new Content(`${process.env.REACT_APP_CMS_BASE_URL}api`);
    this.url = url;
  }

  async run() {
    this.response = await this.model.fetchNoJson(this.url);

    return this.response;
  }
}
export default FetchAny;
