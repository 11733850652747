import CasinoBase from './base';

class Payment extends CasinoBase {
  getDepositMethods(sessionKey) {
    this.response = this.request('get', 'getDepositMethods', { sessionKey });

    return this.response;
  }

  prepareDeposit(parameters) {
    this.response = this.request('get', 'prepareDeposit', parameters);

    return this.response;
  }

  processWithdrawal(parameters) {
    this.response = this.request('get', 'processWithdrawal', parameters);

    return this.response;
  }

  getWithdrawalMethods(sessionKey) {
    this.response = this.request('get', 'getWithdrawalMethods', { sessionKey });

    return this.response;
  }
}

export default Payment;
