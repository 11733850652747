import Content from './base';

class Tournament extends Content {
  constructor() {
    super();
    this.path = 'tournaments/';
  }

  players(parameters) {
    this.response = this.request('post', `${this.path}players/`, parameters);

    return this.response;
  }
}

export default Tournament;
