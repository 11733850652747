import Service from '../service';
import TruutBase from '../models/truut/base';

class AmlCheck extends Service {
  constructor(params) {
    super();
    this.params = params;
    this.truut = new TruutBase();
  }

  async run() {
    await this.truut.request('post', 'check_aml.php', this.params);
  }
}

export default AmlCheck;
