import ServerErrorResponse from './rest/serverErrorResponse';

class Service {
  constructor() {
    this.cookieName = process.env.REACT_APP_API_COOKIE_NAME;
    this.response = '';
  }

  before() {
    // override per service as needed
  }

  run() {
    // override per service as needed
  }

  after() {
    // override per service as needed
  }

  async perform() {
    try {
      await this.before();
      await this.run();
      await this.after();
    } catch (e) {
      this.response = new ServerErrorResponse();
    }
    return this.response;
  }
}
export default Service;
