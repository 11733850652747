/* Istanbul ignore file */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import momentTz from 'moment-timezone';
import i18next from 'i18next';
import cryptoCurrencies from '../utilities/crypto_currencies.json';
import { CryptoContext, CryptoContextProvider } from '../context/CryptoContext';
import {
  CryptoBanner, CryptoDeposit, CryptoNotification, CryptoWithdrawal,
} from './CryptoElements';
import PlayerInfo from '../services/account/playerInfo';

export class CryptoPayment extends PureComponent {
  constructor(props) {
    super(props);
    this.defaultCurrency = 'BTC';

    this.state = {
      currencies: [],
      addresses: [],
      selectedAddress: null,
      selectedCurrency: this.defaultCurrency,
      isCryptoSelected: false,
      isLoading: true,
      withdrawalAddress: '',
      withdrawalAmount: '',
      withdrawalErrorMessage: null,
      playerBonuses: [],
      bonusCode: this.props.defaultBonusCode || '',
      errorNotification: null,
      successNotification: null,
      submittedBonus: null,
      withdrawalLimits: {
        min: '20',
        max: '500',
      },
      playerInfo: null,
      depositResponse: {
        isPaymentSuccess: false,
        depositDetails: {},
      },
    };
    this.startDate = momentTz().format('YYYY-MM-DD HH:mm:ss');
  }

  componentDidMount() {
    this.fetchData();
    this.interval = setInterval(() => this.checkSuccessfulPayment(), 30000);
  }

  componentDidUpdate(prevProps, prevState) {
    const { state } = this;

    if (!prevState.isCryptoSelected
      && state.isCryptoSelected
      && state.bonusCode
      && state.submittedBonus === null
    ) {
      this.onBonusClick();
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  fetchData = async () => {
    const { state } = this;

    try {
      let selectedAddress;

      this.getPlayerBonuses();
      let addresses = await this.context.getCryptoAddresses();
      const currencies = await this.context.getCryptoCurrencies();
      const { min, max } = await this.context.getWithdrawalLimits();
      const playerInfo = await new PlayerInfo(this.context.sessionKey).perform();

      if (addresses.length === 0) {
        addresses = await this.createAddress(state.selectedCurrency);
      }

      const defaultAddress = addresses
        .find(address => address.cryptoCurrency === this.defaultCurrency);

      if (defaultAddress) {
        selectedAddress = defaultAddress;
      } else {
        selectedAddress = addresses[0];
      }

      this.setState(prevState => ({
        ...prevState,
        addresses,
        currencies,
        selectedAddress,
        isLoading: false,
        withdrawalLimits: {
          min,
          max,
        },
        playerInfo: playerInfo.data,
      }));
    } catch (e) {
      this.setState({
        ...state,
        isLoading: false,
      });
    }
  };

  getPlayerBonuses = () => {
    this.context.getPlayerBonuses().then((playerBonuses) => {
      this.setState(prevState => ({
        ...prevState,
        playerBonuses,
      }));
    });
  };

  checkSuccessfulPayment = async () => {
    const isSuccess = await this.context.checkIfPaymentSuccessful(this.startDate);

    if (isSuccess) {
      this.context.onSuccess();
      this.setState(prevState => ({
        ...prevState,
        depositResponse: {
          isPaymentSuccess: true,
          depositDetails: isSuccess,
        },
      }));
    }
  };

  createAddress = async currency => await this.context.createCryptoAddress(currency);

  loading = () => {
    const { state } = this;

    this.setState({
      ...state,
      isLoading: true,
    });
  };

  onCurrencySelect = async (selectedCurrency) => {
    const { state } = this;

    this.loading();

    let addresses = [];

    const addressByCurrency = this.getAddressByCurrency(selectedCurrency);

    let selectedAddress = addressByCurrency;

    if (typeof addressByCurrency === 'undefined') {
      addresses = await this.createAddress(selectedCurrency);

      const newAddressByCurrency = this.getAddressByCurrency(selectedCurrency, addresses);

      if (newAddressByCurrency) {
        selectedAddress = newAddressByCurrency;
      }
    }

    this.setState({
      ...state,
      selectedCurrency,
      selectedAddress,
      addresses: [...state.addresses, ...addresses],
      isLoading: false,
    });
  };

  getAddressByCurrency = (currency, addresses = null) => {
    const { state } = this;

    const addressesToFilter = addresses || state.addresses;

    return addressesToFilter.find(address => address.cryptoCurrency === currency);
  };

  onBannerClick = (e) => {
    e.preventDefault();
    this.toggleBanner();
    this.fixIphoneIssue();
  };

  onBonusClick = async () => {
    const { state, context } = this;
    let newState;

    const bonus = state.playerBonuses.find(bonus => bonus.bonusPlanId === Number(state.bonusCode));

    if (bonus) {
      await context.submitBonus(bonus.optInId);

      newState = {
        submittedBonus: bonus,
        successNotification: i18next.t('crypto.successful_bonus_redemption', { bonus: bonus.bonusPlanId }),
      };
    } else {
      newState = {
        errorNotification: i18next.t('crypto.no_bonus_found'),
      };
    }

    this.setState(prevState => ({
      ...prevState,
      ...newState,
    }));
  };

  fixIphoneIssue = () => {
    const { props } = this;

    const toggledClass = props.type === 'deposit' ? 'opened-crypto-deposit' : 'opened-crypto';
    const cashierModalContent = document.querySelector('#modal-bambora-cashier .modal-content');

    if (cashierModalContent) cashierModalContent.classList.toggle(toggledClass);
  };

  toggleBanner = () => {
    const { state } = this;

    this.setState({
      ...state,
      isCryptoSelected: !state.isCryptoSelected,
    });
  };

  copyToClipBoard = (text) => {
    navigator.clipboard.writeText(text);
  };

  mapCurrencyName = currency => cryptoCurrencies[currency] || currency;

  prepareSelectOptions = () => {
    const { state } = this;

    return state.currencies.map(currency => ({
      value: currency,
      name: `${this.mapCurrencyName(currency)} - ${currency}`,
      icon: `/images/crypto/${currency.toLowerCase()}.svg`,
    }));
  };

  onFieldChange = (e) => {
    const { name, value } = e.currentTarget;

    this.setState(prevState => ({
      ...prevState,
      [name]: value,
      withdrawalErrorMessage: null,
      errorNotification: null,
      isLoading: false,
    }));
  };

  onWithdrawalRequest = async () => {
    const { state } = this;

    this.loading();

    const success = await this.context.cryptoWithdrawal(
      state.selectedCurrency,
      state.withdrawalAmount,
      state.withdrawalAddress,
    );

    if (success) {
      this.context.onSuccess();
      this.setState(prevState => ({
        ...prevState,
        successNotification: i18next.t('crypto.withdrawal_success'),
        withdrawalAddress: '',
        withdrawalAmount: '',
        isLoading: false,
      }));
    } else {
      this.setState(prevState => ({
        ...prevState,
        withdrawalErrorMessage: i18next.t('sorry_error_occurred'),
        isLoading: false,
      }));
    }
  };

  checkIfStaff = () => {
    const {
      playerInfo: { email },
    } = this.state;

    const staffEmails = ['@dialmarketingservices.com', '@techzia.hu'];

    return staffEmails.some(staffEmail => email.includes(staffEmail));
  };

  render() {
    const { state, props } = this;
    const onlyStaff = process.env.REACT_APP_CRYPTO_ONLY_FOR_STAFF.toLowerCase() === 'true';

    if (onlyStaff && (state.playerInfo === null || !this.checkIfStaff())) {
      return null;
    }

    return (
      <div className={`crypto-wrapper ${props.type}`}>
        {state.isCryptoSelected ? (
          <div className="crypto-body">
            <div className="crypto-body-top">
              <a href="/#" onClick={this.onBannerClick}>
                {i18next.t('crypto.close_crypto')}
              </a>
            </div>
            {state.errorNotification && (
              <CryptoNotification type="is-danger">{state.errorNotification}</CryptoNotification>
            )}
            {state.successNotification && (
              <CryptoNotification type="is-success">
                <span dangerouslySetInnerHTML={{ __html: state.successNotification }} />
              </CryptoNotification>
            )}
            {props.type === 'deposit' ? (
              <CryptoDeposit
                isLoading={state.isLoading}
                selectedAddress={state.selectedAddress}
                currencies={this.prepareSelectOptions()}
                onSelect={this.onCurrencySelect}
                onCopy={this.copyToClipBoard}
                onChange={this.onFieldChange}
                onBonusSubmit={this.onBonusClick}
                depositError={state.depositError}
                submittedBonus={state.submittedBonus}
                bonusCode={state.bonusCode}
                selectedCurrency={state.selectedCurrency}
                depositResponse={state.depositResponse}
                bonusSelector={props.defaultBonusCode !== null}
              />
            ) : (
              <CryptoWithdrawal
                isLoading={state.isLoading}
                currencies={this.prepareSelectOptions()}
                onSelect={this.onCurrencySelect}
                onChange={this.onFieldChange}
                withdrawalAddress={state.withdrawalAddress}
                withdrawalAmount={state.withdrawalAmount}
                onRequest={this.onWithdrawalRequest}
                errorMessage={state.withdrawalErrorMessage}
                playerCurrency={props.playerCurrency}
                withdrawalLimits={state.withdrawalLimits}
                selectedCurrency={state.selectedCurrency}
              />
            )}
          </div>
        ) : (
          <CryptoBanner
            isLoading={state.isLoading}
            onClick={this.onBannerClick}
            currencies={state.currencies}
          />
        )}
      </div>
    );
  }
}

CryptoPayment.contextType = CryptoContext;

export const CryptoPaymentProvider = ({
  sessionKey, onSuccess, type, playerCurrency, defaultBonusCode,
}) => (
  <CryptoContextProvider sessionKey={sessionKey} onSuccess={onSuccess}>
    <CryptoPayment
      type={type}
      playerCurrency={playerCurrency}
      defaultBonusCode={defaultBonusCode}
    />
  </CryptoContextProvider>
);

CryptoPaymentProvider.propTypes = {
  sessionKey: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  playerCurrency: PropTypes.string.isRequired,
  defaultBonusCode: PropTypes.string,
  type: PropTypes.string.isRequired,
};

CryptoPaymentProvider.defaultProps = {
  defaultBonusCode: null,
};

CryptoPayment.propTypes = {
  sessionKey: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  playerCurrency: PropTypes.string.isRequired,
  defaultBonusCode: PropTypes.string,
  type: PropTypes.string.isRequired,
};

export default CryptoPaymentProvider;
