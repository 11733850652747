import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { PromotionPlaceholder } from './PromotionSelector';

const MAX_SPINS = 300;

export const PromotionWelcomboContent = ({
  content,
  selectedOption,
  onCancel,
  onDepositClick,
  bonusLink,
}) => {
  const [active, setActive] = useState(5);

  useEffect(() => {
    setActive(Math.floor(content.length / 2));
  }, [content]);

  const onSlide = (event) => {
    setActive(event.target.value);
  };

  const onCancelClick = (event) => {
    event.preventDefault();
    onCancel();
  };

  return (
    <div
      id="js-cashier-welcombo"
      className={`m-cashier-welcombo${selectedOption !== 'welcombo' ? ' is-hidden' : ''}`}
    >
      <div className="m-cashier-welcombo__holder">
        <div className="m-cashier-welcombo__bg" />
        <div className="m-cashier-welcombo__container has-text-centered has-text-dark">
          <h3 className="has-text-dark is-size-4-mobile is-size-2-tablet is-uppercase">
            <i>
              <b>
                {i18next.t('welcombo')}
              </b>
            </i>
          </h3>
          <p className="is-size-6-mobile is-size-5-tablet">
            <b>
              {i18next.t('mix_and_match')}
              <br />
              {i18next.t('slide_for_more')}
            </b>
          </p>
          <div className="m-cashier-welcombo__parent columns is-mobile is-centered is-vcentered">
            <div className="m-cashier-welcombo__label column is-3-touch is-2-tablet">
              <span id="inputRangeFirstValue" className="m-cashier-welcombo__label-amount">
                {MAX_SPINS - (Number(active) * 30)}
              </span>
              <br />
              <span className="m-cashier-welcombo__label-text is-uppercase">
                {i18next.t('free_spins')}
              </span>
            </div>
            <div className="m-cashier-welcombo__range column is-6-touch is-8-tablet">
              <input
                id="bonusSlider"
                type="range"
                min="0"
                max={content.length - 1}
                step="1"
                onChange={onSlide}
                value={active}
              />
            </div>
            <div className="m-cashier-welcombo__label column is-3-touch is-2-tablet">
              <span id="inputRangeSecondValue" className="m-cashier-welcombo__label-amount">
                {`${Number(content[active]?.percentage)}%`}
              </span>
              <br />
              <span className="m-cashier-welcombo__label-text is-uppercase">
                {i18next.t('bonus')}
              </span>
            </div>
          </div>
        </div>
        <button
          id="delete"
          type="button"
          className="delete js-hide-show"
          data-hide="#js-cashier-welcombo"
          data-show="#js-promotion-options"
          aria-label="close"
          onClick={onCancel}
        />
      </div>
      <div className="m-cashier-welcombo__button has-text-centered">
        <button
          type="button"
          className="button is-large is-success mt-3 mb-3 js-hide-show"
          data-hide="#js-cashier-bonuses"
          data-show="#cashier-deposit-content"
          onClick={() => onDepositClick(content[active].bonusPlanId)}
        >
          <span>
            <span id="buttonSpins is-uppercase">
              {MAX_SPINS - (Number(active) * 30)}
            </span>
            &nbsp;
            {i18next.t('free_spins')}
            &nbsp;+
            <br />
            <span id="buttonBonus">
              {`${Number(content[active]?.percentage)}%`}
            </span>
            &nbsp;
            {i18next.t('bonus')}
          </span>
        </button>
        <p className="has-text-centered mb-3">
          <a
            className="has-text-light-grey js-hide-show welcombo-cancel"
            data-hide="#js-cashier-welcombo"
            data-show="#js-promotion-options"
            href="/#"
            onClick={onCancelClick}
          >
            {i18next.t('cancel')}
          </a>
        </p>
        <p className="has-text-centered">
          <a
            className="has-text-light-grey"
            href={bonusLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {i18next.t('bonus_terms')}
          </a>
        </p>
      </div>
    </div>
  );
};

PromotionWelcomboContent.propTypes = {
  selectedOption: PropTypes.number.isRequired,
  content: PropTypes.instanceOf(Array).isRequired,
  onCancel: PropTypes.func.isRequired,
  onDepositClick: PropTypes.func.isRequired,
  bonusLink: PropTypes.string,
};

PromotionWelcomboContent.defaultProps = {
  bonusLink: '/bonus-rules',
};

const PromotionWelcombo = ({ onOptionClick }) => {
  const content = {
    bonusPlanName: 'Welcombo',
    description: {
      en: {
        subtitle: i18next.t('mix_and_match'),
        Description: i18next.t('slide_for_more'),
      },
      de: {
        subtitle: i18next.t('slide_for_more'),
        Description: i18next.t('mix_and_match'),
      },
    },
  };
  return (
    <div
      className="column is-6 has-text-centered"
      key="welcombo"
    >
      <button
        type="button"
        className="m-cashier-promotion-item js-hide-show placeholder-container welcombo"
        onClick={() => onOptionClick('welcombo')}
      >
        <PromotionPlaceholder content={content} />
      </button>
    </div>
  );
};

PromotionWelcombo.propTypes = {
  onOptionClick: PropTypes.func.isRequired,
};

export default PromotionWelcombo;
