import ForgotPassword from './forgotPassword';

class ForgotPasswordPreStep3 extends ForgotPassword {
  async run() {
    await this.callProcessStep('forgotPasswordPreStep3');

    return this.response;
  }
}

export default ForgotPasswordPreStep3;
