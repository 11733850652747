const currencyMark = {
  EUR: '€',
  USD: '$',
  GBP: '£',
  CAD: '$',
  NOK: 'Kr',
  AUD: '$',
  SEK: 'Kr',
  HUF: 'Ft',
};

export default currencyMark;
