import React from 'react';
import { UserDispatchContext, UserStateContext } from '../context/UserContext';

export const useUserData = () => {
  const context = React.useContext(UserStateContext);

  if (context === undefined) {
    throw new Error('useUserData must be used within a UserContextProvider');
  }

  return context;
};

export const useUserAction = () => {
  const dispatch = React.useContext(UserDispatchContext);

  if (dispatch === undefined) {
    throw new Error('useUserData must be used within a UserdispatchProvider');
  }

  return React.useMemo(
    () => ({
      updateUser: user => dispatch({ type: 'UPDATE_USER', payload: user }),
    }),
    [dispatch],
  );
};
