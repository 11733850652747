import Service from '../../service';
import Account from '../../models/casino/account';

class PlayerInfo extends Service {
  constructor(sessionKey) {
    super();
    this.model = new Account();
    this.sessionKey = sessionKey;
  }

  async run() {
    this.response = await this.model.fetchPlayerInfo(this.sessionKey);

    return this.response;
  }
}

export default PlayerInfo;
