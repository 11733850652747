import Service from '../service';
import currenciesCsv from './country-currency.json';
import Currency from '../models/casino/currency';
import FetchContent from './content/fetchContent';

class GetCurrencies extends Service {
  constructor(country) {
    super();
    this.country = country;
  }

  async run() {
    const currenciesByOmega = await new Currency().all();
    const siteSetting = await new FetchContent('site-settings/').perform();
    const currenciesByCms = this.processCmsCurrencies(siteSetting.items()[0].country_currency);

    let allowedCurrencies;

    if (currenciesByCms.length > 0) {
      allowedCurrencies = currenciesByCms;
    } else {
      const currency = currenciesCsv.find(item => item.ISO === this.country);
      allowedCurrencies = (currency) ? currency.Currency.split(',').map(item => item.trim()) : [];
    }

    super.response = currenciesByOmega
      .filter(currency => allowedCurrencies.includes(currency.value))
      .map(currency => currency.value);
  }

  processCmsCurrencies(currencies) {
    try {
      const filtered = currencies.find(currency => currency.country === this.country);

      if (typeof filtered === 'undefined') return [];

      return filtered.currencies.split(',').map(item => item.trim());
    } catch (e) {
      return [];
    }
  }
}
export default GetCurrencies;
