import CasinoBase from './base';

class resendVerification extends CasinoBase {
  constructor(username, target) {
    super();
    this.username = username;
    this.target = target;
  }

  async create() {
    this.response = await this.request('get', 'resendVerificationCode', {
      username: this.username,
      verificationTarget: this.target,
    });

    return this.response;
  }
}
export default resendVerification;
