import Service from '../../service';
import Content from '../../models/content/base';

class FetchContent extends Service {
  constructor(url, locale = null) {
    super(locale);
    this.model = new Content();
    this.url = url;
  }

  async run() {
    this.response = await this.model.fetch(this.url);

    return this.response;
  }
}
export default FetchContent;
