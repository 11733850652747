import Service from '../../service';
import RedirectModel from '../../models/content/redirect';

class FetchRedirect extends Service {
  constructor(url) {
    super();
    this.model = new RedirectModel();
    this.url = url;
  }

  async run() {
    this.response = await this.model.fetch(this.url);

    return this.response;
  }
}
export default FetchRedirect;
