import i18next from 'i18next';
import { config as i18nextConfig } from '../translations';

i18next.init(i18nextConfig);

class ServerErrorResponse {
  success() {
    return false;
  }

  message() {
    return i18next.t('server_error');
  }
}
export default ServerErrorResponse;
