import Model from '../model';

/**
 * Provides a base class for defining common behaviours shared across any models that interact
 * with the Casino API
 *
 * This should be kept abstract, and not be called directly.
 */
class Content extends Model {
  constructor(endpoint = process.env.REACT_APP_API_BASE_URL) {
    super();
    this.endpoint = endpoint;
    this.response = null;
  }

  async fetch(url) {
    this.response = await this.request('get', url);

    return this.response;
  }

  async fetchStream(url) {
    this.response = await this.request('getStream', url);

    return this.response;
  }

  async fetchNoJson(url) {
    this.response = await this.request('getNotJson', url);

    return this.response;
  }
}
export default Content;
