import Session from '../models/casino/session';
import Service from '../service';
import Cookie from '../cookie';

class Logout extends Service {
  constructor(executed) {
    super();
    this.session = new Session(null, null, executed);
    this.cookie = new Cookie();
    this.sessionKey = '';
  }

  /*
   * Perform the heavy lifting for this service, trigger respective callbacks from frontend
   */
  async run() {
    if (document.cookie.indexOf(this.cookieName) >= 0) {
      const cookieContent = JSON.parse(this.cookie.get(this.cookieName));
      this.sessionKey = cookieContent.sessionKey;
      this.cookie.delete(this.cookieName);
    }
    const response = await this.session.destroy(this.sessionKey);
    super.response = response;
  }
}
export default Logout;
