import Model from '../model';

class TruutBase extends Model {
  constructor() {
    super();
    this.endpoint = process.env.REACT_APP_TRUUT_BASE_URL;
  }

  async request(method, path, params) {
    return super.request(method, path, params);
  }
}
export default TruutBase;
