import resources from './resources';

export const config = {
  debug: false,
  interpolation: { escapeValue: false },
  lng: 'en',
  fallbackLng: 'en',
  defaultNS: 'common',
  resources,
};

export { resources };
