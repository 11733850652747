import crypto from 'crypto';
import Model from '../model';

class CoinspaidBase extends Model {
  constructor() {
    super();
    this.endpoint = process.env.REACT_APP_COINSPAID_API_URL;
    this.secretKey = process.env.REACT_APP_COINSPAID_SECRET_KEY;
    this.apiKey = process.env.REACT_APP_COINSPAID_API_KEY;
  }

  async currencyPairs(parameters = '') {
    const extraHeaders = this.generateExtraHeaders(parameters);

    this.response = await this.request('post', 'currencies/pairs', parameters, extraHeaders);

    return this.response;
  }

  generateExtraHeaders(parameters) {
    const hmac = crypto.createHmac('sha256', this.secretKey);
    const data = hmac.update(JSON.stringify(parameters));

    const signature = data.digest('hex');

    return {
      'X-Processing-Key': this.apiKey,
      'X-Processing-Signature': signature,
      'Content-Type': 'application/json',
    };
  }
}

export default CoinspaidBase;
