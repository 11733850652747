import Service from '../../service';
import Payment from '../../models/casino/payment';
import Account from '../../models/casino/account';
import Cookie from '../../cookie';
import CoinspaidBase from '../../models/coinspaid/base';

class CryptoService extends Service {
  constructor(sessionKey) {
    super();
    this.sessionKey = sessionKey;
    this.model = new Payment();
    this.paymentMethod = 'CP_COINSPAID';
  }

  async getCryptoAddresses() {
    const { sessionKey, paymentMethod } = this;
    const parameters = {
      sessionKey,
      paymentMethod,
      action: 'GET_CRYPTO_ADDRESS',
    };
    return await this.model.prepareDeposit(parameters);
  }

  async getCryptoCurrencies() {
    const { sessionKey, paymentMethod } = this;
    const parameters = {
      sessionKey,
      paymentMethod,
      action: 'GET_SUPPORT_CRYPTO',
    };
    return await this.model.prepareDeposit(parameters);
  }

  async createCryptoAddress(currency) {
    const { sessionKey, paymentMethod } = this;
    const parameters = {
      sessionKey,
      paymentMethod,
      action: 'CREATE_CRYPTO_ADDRESS',
      currency,
    };
    return await this.model.prepareDeposit(parameters);
  }

  async cryptoWithdrawal(currency, amount, address) {
    const { sessionKey, paymentMethod } = this;
    const parameters = {
      sessionKey,
      paymentMethod,
      cryptoCurrency: currency,
      amount,
      address,
    };
    return await this.model.processWithdrawal(parameters);
  }

  async getWithdrawalMethods() {
    const { sessionKey } = this;

    return await this.model.getWithdrawalMethods(sessionKey);
  }

  async getPlayerBonuses() {
    const { sessionKey } = this;

    return await new Account().getEligibleOptInBonusPlans(sessionKey, null, 'DEPOSIT');
  }

  async submitBonus(optInId) {
    const { sessionKey } = this;
    const { partyId } = new Cookie().get('Session');

    const params = {
      sessionKey,
      optInId,
      partyId,
    };

    return await new Account().optInBonus(params);
  }

  async cancelBonus(bonusId) {
    const { sessionKey } = this;
    const { partyId } = new Cookie().get('Session');

    const params = {
      sessionKey,
      bonusId,
      partyId,
    };

    return await new Account().optOutBonus(params);
  }

  async getCryptoCurrencyPairs(parameters = '') {
    return await new CoinspaidBase().currencyPairs(parameters);
  }
}

export default CryptoService;
