import DBBase from './base';

class DB extends DBBase {
  async getTranslation(parameters) {
    this.response = await this.request('post', 'translations/translate', parameters);

    return this.response;
  }

  async setTranslation(parameters) {
    this.response = await this.request('post', 'translations', parameters);

    return this.response;
  }
}
export default DB;
