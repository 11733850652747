import Service from '../service';
import ResendVerification from '../models/casino/resendVerification';

class ResendVerificationCode extends Service {
  constructor(username, target) {
    super();
    this.username = username;
    this.target = target;
    this.signupConfirmation = new ResendVerification(this.username, this.target);
  }

  async run() {
    const response = await this.signupConfirmation.create();

    super.response = response;
  }
}
export default ResendVerificationCode;
