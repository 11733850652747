import Content from './base';
import PageResponse from '../../rest/content/pageResponse';
import Rest from '../../rest';

class PageModel extends Content {
  constructor() {
    super();
    this.findPath = 'pages/find/';
    this.pages = 'pages/';
  }

  async fetch(url) {
    this.response = await this.request('get', this.findPath, { html_path: url });

    return this.response;
  }

  async request(method, path, parameters) {
    const rest = new Rest();
    const request = await rest.request(method, this.endpointFor(path), parameters);

    return PageModel.responseFor(request);
  }

  static responseFor(response) {
    return new PageResponse(response);
  }
}

export default PageModel;
