import React from 'react';
import QRCode from 'qrcode.react';
import PropTypes from 'prop-types';

const QrCode = ({ value, size }) => (
  <QRCode
    value={value}
    size={size}
  />
);

QrCode.propTypes = {
  value: PropTypes.string.isRequired,
  size: PropTypes.number,
};

QrCode.defaultProps = {
  size: 128,
};

export default QrCode;
