import Content from './base';

class Surveys extends Content {
  constructor(path) {
    super();
    this.path = path;
  }

  getSurveys(parameters) {
    this.response = this.request('get', this.path, parameters);
    return this.response;
  }

  createSurveys(parameters) {
    this.response = this.request('post', this.path, parameters);
    return this.response;
  }

  editSurveys(parameters) {
    this.response = this.request('patch', this.path, parameters);
    return this.response;
  }

  uploadSurveyDocuments(data) {
    const { id, documentType, file } = data;
    const formData = new FormData();

    formData.append('id', id);
    formData.append('file', file);
    formData.append('type', documentType);

    const baseHostUrl = window.location.host;
    const qaHostUrl = process.env.REACT_APP_FRONTEND_URL;
    const Authorization = process.env.REACT_APP_CMS_AUTHORIZATION;
    const headers = {
      'X-Forwarded-Host': baseHostUrl.includes('localhost') ? qaHostUrl : baseHostUrl,
      Authorization,
    };

    this.response = this.request('put', this.path, formData, headers);
    return this.response;
  }
}

export default Surveys;
