// eslint-disable-next-line no-unused-vars
import { propTypes } from 'qrcode.react';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

class CustomSelect extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      selectedOption: {
        name: 'Please select..',
        value: null,
      },
      showDropdown: false,
    };
  }

  componentDidMount() {
    const { props, state } = this;

    document.addEventListener('mousedown', this.handleClickOutside);

    if (props.items.length > 0 && props.items[0].value !== state.selectedOption.value) {
      this.setState(prevState => ({
        ...prevState,
        selectedOption: props.items[0],
      }));
    }
  }

  handleClickOutside = (event) => {
    if (
      !event.target.parentElement.classList.contains('custom-select-container')
      && !event.target.parentElement.classList.contains('select-options')
      && !event.target.parentElement.classList.contains('selected-option')
    ) {
      this.setState(prevState => ({
        ...prevState,
        showDropdown: false,
      }));
    }
  };

  toggleDropdown = () => {
    this.setState(prevState => ({
      ...prevState,
      showDropdown: !prevState.showDropdown,
    }));
  };

  onOptionClick = (option) => {
    const { props } = this;

    this.setState(prevState => ({
      ...prevState,
      selectedOption: option,
      showDropdown: false,
    }));

    props.onSelect(option.value);
  };

  onImageError = (e) => {
    e.target.style.visibility = 'hidden';
  };

  render() {
    const { props, state } = this;
    return (
      <div className="custom-select-container">
        <div
          className="selected-option"
          onClick={this.toggleDropdown}
        >
          {state.selectedOption.icon && (
            <img
              alt=""
              src={state.selectedOption.icon}
              onError={this.onImageError}
            />
          )}
          <span>
            {state.selectedOption.name}
          </span>
          {state.showDropdown ? (
            <i className="fas fa-caret-up"/>
          ) : (
            <i className="fas fa-caret-down"/>
          )}
        </div>
        {state.showDropdown && (
          <ul className="select-options">
            {props.items.filter(item => item.value !== state.selectedOption.value).map(item => (
              <li
                className="custom-select-option"
                key={item.value}
                onClick={() => this.onOptionClick(item)}
              >
                {item.icon && (
                  <img
                    src={item.icon}
                    alt=""
                    onError={this.onImageError}
                  />
                )}
                {item.name}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}

CustomSelect.propTypes = {
  items: PropTypes.instanceOf(Array),
  onSelect: PropTypes.func.isRequired,
};

CustomSelect.defaultProps = {
  items: [],
};

export default CustomSelect;
