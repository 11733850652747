import CasinoBase from './base';

class AccountVerification extends CasinoBase {
  check2kDeposit(partyid, method) {
    this.response = this.request('get', '2k/limit', { partyid, method });

    return this.response;
  }

  kycHooyuRequest(partyid) {
    this.response = this.request('get', 'kyc/hooyu', { partyid });

    return this.response;
  }

  kycCallbackResult(partyid) {
    this.response = this.request('get', 'kyc/doc', { partyid });

    return this.response;
  }

  kycCheck(partyid) {
    this.response = this.request('get', 'kyc/check', { partyid });

    return this.response;
  }

  userKycCheck(parameters) {
    this.response = this.request('post', 'user/kyc/check', parameters);

    return this.response;
  }

  userKycInfo(parameters) {
    this.response = this.request('get', 'user/kyc/info', parameters);

    return this.response;
  }

  getDocumentTypes(parameters) {
    this.response = this.request('get', 'getDocumentTypes', parameters);

    return this.response;
  }

  getUserDocuments(parameters) {
    this.response = this.request('get', 'getUserDocuments', parameters);

    return this.response;
  }

  uploadMultiUserDocument(
    sessionKey, docNumber, documentType, expiryDate, issueDate, playerFiles,
  ) {
    const fd = new FormData();

    const adjustPlayerFiles = () => {
      const returnValue = {};
      Object.entries(playerFiles).forEach(([, value], index) => {
        returnValue[`file${index + 1}`] = value;
      });
      return returnValue;
    };

    const files = adjustPlayerFiles();
    const fileMap = files.file3
      ? `{"${files.file1.name}":"FRONT","${files.file2.name}":"CONTENT","${files.file3.name}":"BACK"}`
      : `{"${files.file1.name}":"FRONT","${files.file2.name}":"BACK"}`;

    const data = {
      sessionKey, documentType, issueDate, expiryDate, documentNumber: docNumber, fileMap,
    };

    Object.keys(data).forEach(key => fd.append(`${key}`, data[key]));

    if (files.file2 && files.file3) {
      fd.append('_file_0', files.file1, files.file1.name);
      fd.append('_file_1', files.file2, files.file2.name);
      fd.append('_file_2', files.file3, files.file3.name);
    } else {
      fd.append('_file_0', files.file1, files.file1.name);
      fd.append('_file_1', files.file2, files.file2.name);
    }

    this.response = this.request('postNotJson', 'uploadMultiUserDocument', fd);

    return this.response;
  }
}

export default AccountVerification;
