import Service from '../../service';
import PageModel from '../../models/content/page';

class FetchPage extends Service {
  constructor(url) {
    super();
    this.model = new PageModel();
    this.url = url;
  }

  async run() {
    this.response = await this.model.fetch(this.url);
    return this.response;
  }
}
export default FetchPage;
